export const HelpPanelLinks = [
  {
    text: 'Privacy Statement',
    link: 'http://aka.ms/MSA_CFIT_MA_Privacy',
    target: '_blank',
  },
  {
    text: 'Legal Notice',
    link: 'http://aka.ms/MSA_CFIT_MA_Legal',
    target: '_blank',
  },
  {
    text: 'Data Privacy Notice',
    link: 'https://privacy.microsoft.com/en-US/data-privacy-notice',
    target: '_blank',
  },
];
