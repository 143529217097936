export const navConfig = [
  {
      key: 'Main',
      links: [
          {
              key: 1,
              name: 'Home',
              href: '/',
              icon: 'Home',
          }
      ],
  },
];

export const authorizedConfig = [
  {
      key: 'Main',
      links: [
          {
              key: 'Home',
              name: 'Home',
              href: '/',
              icon: 'Home',
              ariaLabel: 'Home',
          },
          {
              Key: 'AdminView',
              name: 'Administrator View',
              href: '/AdminView',
              icon: 'AccountManagement',
              ariaLabel: 'Administrator View',
          },
          {
              Key: 'GlobalAuditChecklist',
              name: 'Global Audit Checklist',
              href: 'https://msit.powerbi.com/groups/me/reports/5138c73c-a77b-4842-a747-f9bcd97b2223/ReportSection',
              icon: 'WebSite',
              ariaLabel: 'Global Audit Checklist',
          },
          {
              Key: 'TGHTool',
              name: 'TGH Tool',
              href: 'https://msit.powerbi.com/groups/me/apps/1553126c-d13f-48b8-b2f8-d6085bdaa9f2/reports/5d37ba16-b0ff-4bb1-bc4d-44d54bee755e/ReportSectiona43dc742e84e0c0a0e09?ctid=72f988bf-86f1-41af-91ab-2d7cd011db47',
              icon: 'WebSite',
              ariaLabel: 'TGH Tool',
          }
      ],
  },
];
