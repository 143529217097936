export default {
    "type": "AdaptiveCard",
    "version": "1.5",
    "fallbackText": "This device does not support yet, please upgrade your outlook version",
    "body": [
        {
            "type": "ColumnSet",
            "height": "stretch",
            "columns": [
                {
                    "type": "Column",
                    "width": 1,
                    "items": [
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Status",
                            "id": "status",
                            "isRequired": true,
                            "errorMessage": "Please fill out the Audit Status",
                            "choices": "${auditAttributeOptions.auditStatus}",
                            "value": "${lineLevelBeingAudited.lineAuditStatus}"
                        },
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Conclusion",
                            "id": "conclusion",
                            "choices": "${auditAttributeOptions.auditAction}",
                            "value": "${lineLevelBeingAudited.lineAuditConclusion}"
                        },
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Risk Feedback",
                            "id": "feedback",
                            "isRequired": true,
                            "$when": "${lineLevelBeingAudited.showAuditFeedback}",
                            "errorMessage": "Please select an option for Audit Feedback",
                            "choices": "${auditAttributeOptions.auditFeedback}",
                            "value": "${lineLevelBeingAudited.lineAuditFeedback}"
                        },
                        {
                            "type": "TextBlock",
                            "text": "Audit Initial Reasons",
                            "horizontalAlignment": "Left"
                        },
                        {
                            "id": "auditInitialReasons",
                            "type": "TextBlock",
                            "wrap": true,
                            "text": "${lineLevelBeingAudited.lineAuditInitialReasons} ",
                            "horizontalAlignment": "Left"
                        },
                        {
                            "type": "TextBlock",
                            "$when": "${and(exists(expenseAuditData.crmCode), expenseAuditData.crmCode != '')}",
                            "text": "CRM Ticket"
                        },
                        {
                            "id": "crmCode",
                            "type": "TextBlock",
                            "wrap": true,
                            "$when": "${and(exists(expenseAuditData.crmCode), expenseAuditData.crmCode != '')}",
                            "text": "${expenseAuditData.crmCode} ",
                            "horizontalAlignment": "Left"
                        },
                        {
                            "type": "Input.Number",
                            "label": "Recovery Amount USD",
                            "id": "recoveryAmountUsd",
                            "isMultiSelect": false,
                            "value": "${lineLevelBeingAudited.lineRecoveryAmountUsd}"
                        },
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Final Reasons",
                            "id": "auditFinalReasons",
                            "isMultiSelect": true,
                            "choices": "${auditAttributeOptions.auditReason}",
                            "value": "${lineLevelBeingAudited.lineFinalReasonsAdaptiveCard}",
                            "wrap": true
                        },
                        {
                            "$data": "${lineLevelBeingAudited.lineModuleScoresAdaptiveCard}",
                            "type": "Container",
                            "style": "default",
                            "items": [
                                {
                                    "$when": "${$index == 0}",
                                    "type": "TextBlock",
                                    "text": "Module Risk Indicator(s)",
                                    "height": "stretch"
                                },
                                {
                                    "type": "ColumnSet",
                                    "id": "lineModuleRiskIndicator${$index}",
                                    "$when": "${and(exists(moduleName), moduleName != '')}",
                                    "columns": [
                                        {
                                            "type": "Column",
                                            "items": [
                                                {
                                                    "type": "TextBlock",
                                                    "$when": "${and(and(exists(moduleRiskIndicator), moduleRiskIndicator != ''), moduleRiskIndicator == 'High')}",
                                                    "text": "${moduleName}",
                                                    "spacing": "None",
                                                    "weight": "Bolder",
                                                    "color": "Attention",
                                                    "size": "Small"
                                                },
                                                {
                                                    "type": "TextBlock",
                                                    "$when": "${and(and(exists(moduleRiskIndicator), moduleRiskIndicator != ''), moduleRiskIndicator == 'Medium')}",
                                                    "text": "${moduleName}",
                                                    "spacing": "None",
                                                    "weight": "Bolder",
                                                    "color": "Warning",
                                                    "size": "Small"
                                                }
                                            ],
                                            "padding": "None",
                                            "width": 1
                                        },
                                        {
                                            "type": "Column",
                                            "verticalContentAlignment": "Center",
                                            "id": "lineAnomalyScoreIcon",
                                            "items": [
                                                {
                                                    "type": "Image",
                                                    "$when": "${and(and(exists(moduleRiskIndicator), moduleRiskIndicator != ''), moduleRiskIndicator == 'High')}",
                                                    "url": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAMAAAAMCGV4AAAAXVBMVEXoPEHsHyfrHCTqKTDqHSXsHyfsHibrHSTqKjDsICfsHyfWHyfpHSTYICfrHCTfHibdHybWHCTYKzL//v/2zc/ZHSXvqazne4DgU1n42tviXmPdRkvpiIzaNTvuoaRFiu+MAAAAC3RSTlMBlvIS9X+p/Q5wm53I2xwAAACFSURBVAjXTc9JEsMgDARAxQs4FmL3buf/z4wEPjAXqkdQAABMypboESTTRztaloVc932JGPcH0Q1cKCH+0kpcdCNYYczpiLw6DYaqs5hMNa5pxcZhS1tofR17a4xnfPdbL/M9nzL3mu/35fzN9sMI/Wy4eO5LWB48a08YkCoBemWNpPzvDwbdCrcqjnhpAAAAAElFTkSuQmCC",
                                                    "altText": "High risk icon",
                                                    "horizontalAlignment": "Center"
                                                },
                                                {
                                                    "type": "Image",
                                                    "$when": "${and(and(exists(moduleRiskIndicator), moduleRiskIndicator != ''), moduleRiskIndicator == 'Medium')}",
                                                    "url": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgBldLbCYAwDAXQ5OJAukHdS1TQvXQDcR/BR1EpmMT0/pQ0hENpiIysbdFZfWiNpSnCvlN/ndnDTBRVZuqyhqPGFO4yaDos9a0VHZZaDhtbOv5US4emCvno8KiaDqcq6vCqks6Pel5O5My5eXU1bjMsNfkqUedcNdXhfaukH8utK/v8wDLrAAAAAElFTkSuQmCC",
                                                    "altText": "Medium risk icon",
                                                    "horizontalAlignment": "Center"
                                                }
                                            ],
                                            "padding": "none",
                                            "width": "stretch"
                                        }
                                    ],
                                    "padding": "None"
                                }
                            ]
                        }
                    ],
                    "minHeight": "200px"
                }
            ]
        },
        {
            "type": "Container",
            "$when": "${count(expenseAuditData.caseLogsHeader) > 0}",
            "id": "dataToToggle",
            "padding": "Small",
            "items": [
                {
                    "type": "Container",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "Audit Comments History",
                            "size": "Medium",
                            "weight": "Bolder"
                        }
                    ]
                },
                {
                    "type": "Table",
                    "separator": true,
                    "firstRowAsHeaders": true,
                    "columns": [
                        {
                            "width": 1
                        },
                        {
                            "width": 5
                        },
                        {
                            "width": 2
                        }
                    ],
                    "rows": [
                        {
                            "type": "TableRow",
                            "cells": [
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Log Date"
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Audit Comment"
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Created By"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "$data": "${expenseAuditData.caseLogsHeader}",
                            "type": "TableRow",
                            "cells": [
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${formatDateTime(loggedDate, 'MM-dd')}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${auditorComments}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${createdBy}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "minHeight": "150px",
            "additionalProperties": {
                "padding": "Default"
            },
            "internalID": {}
        },
        {
            "type": "Container",
            "items": [
                {
                    "type": "Input.Text",
                    "isMultiline": true,
                    "id": "auditComments",
                    "height": "stretch",
                    "label": "Audit comments"
                }
            ],
            "minHeight": "250px"
        },
        {
            "type": "ColumnSet",
            "columns": [
                {
                    "type": "Column",
                    "width": "140px",
                    "items": [
                        {
                            "type": "ActionSet",
                            "actions": [
                                {
                                    "type": "Action.Submit",
                                    "title": "Submit",
                                    "style": "positive",
                                    "id": "SubmitLineLevel"
                                }
                            ]
                        }
                    ]
                },
                {
                    "type": "Column",
                    "width": "140px",
                    "items": [
                        {
                            "type": "ActionSet",
                            "actions": [
                                {
                                    "type": "Action.Submit",
                                    "title": "Cancel",
                                    "style": "destructive",
                                    "associatedInputs": "none",
                                    "id": "ActionShowAuditDetail"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    "additionalProperties": {
        "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
        "correlationId": null,
        "constrainWidth": true,
        "hideOriginalBody": true,
        "padding": "None"
    },
    "internalID": {},
    "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
    "correlationId": null,
    "constrainWidth": true,
    "hideOriginalBody": true,
    "padding": "None",
    "height": "stretch",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json"
};