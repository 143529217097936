import styled from 'styled-components';

export const Main = styled.main`
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
  padding-top: 45px;
  padding-left: 48px;
  background: rgb(242, 242, 242);
`;
