import * as React from 'react';
import * as AdaptiveCards from 'adaptivecards';
import * as MarkdownIt from 'markdown-it';
import { hostConfig } from '../AdaptiveCardHelpers/AdaptiveHostConfig';
import * as ACData from 'adaptivecards-templating';
import { MultiChoiceInput } from './MultiChoiceInput';

interface IAdaptiveProps {
  payload: any;
  cardInfo: any;
  style: any;
  onOpenURLActionExecuted: any;
  onSubmitActionExecuted: any;
}
AdaptiveCards.GlobalRegistry.elements.register(MultiChoiceInput.JsonTypeName, MultiChoiceInput);
export class Adaptive extends React.Component<IAdaptiveProps> {
  private adaptiveCard = new AdaptiveCards.AdaptiveCard();

  shouldComponentUpdate(nextProps: IAdaptiveProps): boolean {
    return nextProps.payload !== this.props.payload;
  }

  public render(): React.ReactElement {
    const openURLclickHandler = this.props.onOpenURLActionExecuted;
    const onSubmitActionExecuted = this.props.onSubmitActionExecuted;
    const cardInfo = this.props.cardInfo;
    try {
      AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
        result.outputHtml = MarkdownIt().render(text);
        result.didProcess = true;
      };

      this.adaptiveCard.onExecuteAction = function (action) {
        const type = action.getJsonTypeName();
        if (type === 'Action.OpenUrl') {
          if (openURLclickHandler) {
            openURLclickHandler(action.getHref(), action.id, action.title);
          }
        } else if (type === 'Action.Submit') {
          if (onSubmitActionExecuted) {
            onSubmitActionExecuted(action.id, action.data);
          }
        }
      };

      this.adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(hostConfig);
      // Create a Template instance from the template payload
      const template = new ACData.Template(this.props.payload[0]);
      // Create a data binding context, and set its $root property to the
      // data object to bind the template to
      const context: ACData.IEvaluationContext = {
        $root: cardInfo,
      };

      // "Expand" the template - this generates the final Adaptive Card,
      // ready to render
      const card = template.expand(context);
      this.adaptiveCard.parse(card);
      const result = this.adaptiveCard.render();
      result.setAttribute('tabindex', '-1');
      const elements = result.getElementsByClassName('ac-horizontal-separator');
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('aria-hidden', 'true');
      }
      const ariafix = result.getElementsByClassName('ac-actionSet');
      for (let i = 0; i < ariafix.length; i++) {
        ariafix[i].setAttribute('role', '');
      }
      return (
        <div
          style={this.props.style}
          ref={(n) => {
            n != null && n.firstChild && n.removeChild(n.firstChild);
            n != null && n.appendChild(result);
          }}
        />
      );
    } catch (err) {
      console.error(err);
      return <div style={{ color: 'red' }}>{err.message}</div>;
    }
  }
}
