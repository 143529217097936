import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { useLoginOnStartup } from '@micro-frontend-react/employee-experience/lib/useLoginOnStartup';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { loadTheme, Stack, ThemeProvider } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { ShellWithStore } from './ShellWithStore';
import { authorizedConfig, navConfig } from './navConfig';
import { Routes } from './Routes';
import { useHeaderConfig } from './useHeaderConfig';
import { Header } from './Components/Header/Header';
import { Nav } from './Components/Nav/Nav';
import { initializeOBFeedback } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { IExpenseAppState } from './Shared/Store/ExpenseAudit.types';
import { ExpenseAuditReducerName, ExpenseAuditInitialState } from './Shared/Store/ExpenseAudit.reducer';
import { SecondaryHeader } from './Components/SecondaryHeader/SecondaryHeader';
import { Main } from './Components/Main/Main';
import './App.css';

initializeOBFeedback(
  1111,
  'running-environment',
  '/ocv/OfficeBrowserFeedback.min.js',
  '/ocv/OfficeBrowserFeedback.min.css',
  '/ocv/intl/',
  'https://office365.uservoice.com/'
);

function App(): React.ReactElement {
  useLoginOnStartup();
  loadTheme(CoherenceTheme);
  const headerConfig = useHeaderConfig();
  const { useSelector } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const { isPanelOpen, isAuthorized } = useSelector(
    (state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState
  );
  usePageTitle(__APP_NAME__);

  return (
    <ThemeProvider theme={CoherenceTheme}>
      <BrowserRouter>
        <Header {...headerConfig} />
        <div>
          <Stack horizontal className={isPanelOpen ? 'ms-hiddenSm' : ''}>
            <Stack.Item>
              <div className="navContainer">
                <Nav groups={isAuthorized ? authorizedConfig : navConfig} />
              </div>
            </Stack.Item>
            <Stack.Item grow>
              <SecondaryHeader />
            </Stack.Item>
          </Stack>
          <Main id="main" tabIndex={-1}>
            <Routes />
          </Main>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

render(
  <ShellWithStore>
    <App />
  </ShellWithStore>,
  document.getElementById('app')
);
