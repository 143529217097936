import { ApprovedIcon, InReviewIcon, PaidIcon, ProcessingPaymentIcon } from '../Static/Icons';
import { breakpointMap } from '../Shared/Styles/Media';

export const mapExpenseStatusIcon = (expenseStatus: string) => {
  if (!expenseStatus) return '';
  switch (expenseStatus.toLowerCase()) {
    case 'approved':
      return ApprovedIcon;
      break;
    case 'processing payment':
      return ProcessingPaymentIcon;
      break;
    case 'processed for payment':
      return ProcessingPaymentIcon;
      break;
    case 'in review':
      return InReviewIcon;
      break;
    case 'paid':
      return PaidIcon;
      break;
  }
};

export const convertToUPN = (alias: string): string => {
  if (alias && !alias.includes('@')) {
    return alias + '@microsoft.com';
  }
  return alias;
};

export const isMobileResolution = (width: number): boolean => {
  return width <= breakpointMap.m;
};
