import * as React from 'react';
import * as Styled from '../Styles/SharedLayout.styled';
import { updatePanelState } from '../Store/ExpenseAudit.actions';
import { TooltipHost, IconButton } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';

function CloseButton(): React.ReactElement {
  const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

  return (
    <TooltipHost content="Close" calloutProps={Styled.tooltipCalloutProps} styles={Styled.tooltipHostContainer}>
      <IconButton
        id="detail-close-btn"
        className="close-button"
        iconProps={{ iconName: 'Cancel' }}
        title="Close"
        ariaLabel="Click here to close the details card"
        style={Styled.DetailActionIcon}
        onClick={() => {
          dispatch(updatePanelState(false));
        }}
      />
    </TooltipHost>
  );
}

const connected = withContext(CloseButton);
export { connected as CloseButton };
