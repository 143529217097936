import { SimpleEffect, getContext, put, all, call, takeLatest, select } from 'redux-saga/effects';
import {
  ExpenseAuditActionType,
  IFetchExpenseDataAction,
  IFetchCompleteAuditDataAction,
  ISubmitAuditAction,
  ISubmitLineAuditAction,
  IRequestDocumentPreviewAction,
  IRequestDocumentAction,
  IRequestAllDocumentsAction,
  IRequestAllUserRolesAction,
  IUpdateUserRoleAction,
} from './ExpenseAudit.action-types';
import {
  receiveFriendByEmail,
  setAuditAttributeOptions,
  failedProfile,
  setExpenseData,
  setCompleteAuditData,
  receiveDocumentPreview,
  updatePanelState,
  setAuthorized,
  receiveUserImage,
  failedUserImage,
  requestDocumentEnd,
  failedDocumentDownload,
  failedDocumentPreview,
  failedAllDocumentDownload,
  requestAllDocumentsEnd,
  submitAuditSuccess,
  submitAuditFailed,
  submitLineAuditSuccess,
  submitLineAuditFailed,
  receiveAllUserRoles,
  failedAllUserRoles,
  receiveMyUserRole,
  updateUserRoleSuccess,
  failedUpdateUserRole,
  setIsLineLevelAudit,
  setOpenAuditSubmitDialog,
  setUserChangeData
} from './ExpenseAudit.actions';
import { expenseAuditDataSelector } from './ExpenseAudit.selectors';
import { APP_MESSAGES } from '../AppMessages';
import { convertToUPN } from '../../Helpers/sharedHelpers';
import { IHttpClient, IHttpClientRequest } from '@micro-frontend-react/employee-experience/lib/IHttpClient';

interface IResponseItem {
  name: string;
  id: number;
}

function* fetchProfile(): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const profile: any = yield call([httpClient, httpClient.request], {
      url: `${__GRAPH_BASE_URL__}/me`,
      resource: __GRAPH_RESOURCE_URL__,
    });
    yield put(receiveFriendByEmail(profile));
    if (profile) {
      const response: any = yield call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/Authorization?userName=${profile.data.userPrincipalName}`,
        resource: __RESOURCE_URL__,
      });
      yield put(setAuthorized(response.data));
    }
    // need to get the current user's role
    if (profile) {
      const httpClient: IHttpClient = yield getContext('httpClient');
      const response: any = yield call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/AuditRoles/userRole?userAlias=${profile.data.userPrincipalName}`,
        resource: __RESOURCE_URL__,
      });
      yield put(receiveMyUserRole(response.data));
    }
  } catch (error) {
    yield put(failedProfile(error?.data?.error?.message || APP_MESSAGES.FETCH_PROFILE_ERROR_MESSAGE));
  }
}

function* fetchAuditAttributeOptions() {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const [auditReason, auditStatus, auditAction, auditFeedback] = yield all([
      call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/AuditDomain/reasons`,
        resource: __RESOURCE_URL__,
      }),
      call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/AuditDomain/statuses`,
        resource: __RESOURCE_URL__,
      }),
      call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/AuditDomain/conclusions`,
        resource: __RESOURCE_URL__,
      }),
      call([httpClient, httpClient.request], {
        url: `${__API_BASE_URL__}/AuditDomain/feedback/options`,
        resource: __RESOURCE_URL__,
      }),
    ]);
    yield put(
      setAuditAttributeOptions({
        auditReason: auditReason.data.map((d: IResponseItem) => ({ title: d.name, value: d.name })),
        auditAction: auditAction.data.map((d: IResponseItem) => ({ title: d.name, value: d.name })),
        auditStatus: auditStatus.data.map((d: IResponseItem) => ({ title: d.name, value: d.name })),
        auditFeedback: auditFeedback.data.map((d: IResponseItem) => ({ title: d.name, value: d.name })),
      })
    );
  } catch (error) {
    yield put(updatePanelState(false));
    yield put(
      setAuditAttributeOptions(
        {
          auditReason: [],
          auditAction: [],
          auditStatus: [],
          auditFeedback: [],
        },
        APP_MESSAGES.AUDIT_ATTR_LOAD_ERR
      )
    );
  }
}

function* fetchAuditStatus() {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/AuditDomain/statuses`,
      resource: __RESOURCE_URL__,
    });
  } catch (error) {
    console.log(error);
  }
}

function* submitAudit(action: ISubmitAuditAction) {
  try {
    const { auditData } = action;
    const { expenseData, profile, completeAuditData } = yield select(expenseAuditDataSelector);
    const todayDate = new Date().toISOString();
    const partitionKey = completeAuditData.partitionKey;
    const existingCaseLogs = completeAuditData?.expenseAudit?.caseLogs || [];

    const payload = {
      expenseAudit: {
        expenseReportId: expenseData?.header?.reportId,
        createdDate: completeAuditData?.expenseAudit?.createdDate || null,
        auditInitialReasons: completeAuditData?.expenseAudit?.auditInitialReasons || null,
        auditFinalReasons: auditData?.auditFinalReasons || [],
        status: auditData?.status || null,
        conclusion: auditData?.conclusion || null,
        recoveryAmountUsd: parseFloat(auditData.recoveryAmountUsd) || 0.0,
        crmCode: auditData?.crmCode || null,
        caseLogs:
          auditData?.auditComments == null
            ? [...existingCaseLogs]
            : [
                ...existingCaseLogs,
                {
                  auditorFirstName: profile?.data?.givenName || null,
                  auditorLastName: profile?.data?.surname || null,
                  createdBy: profile?.data?.mail || null,
                  loggedDate: todayDate,
                  auditorComments: auditData.auditComments || '',
                },
              ],
        anomalyScore: completeAuditData?.expenseAudit?.anomalyScore || null,
        anomalyFlag: completeAuditData?.expenseAudit?.anomalyFlag || null,
        isLineLevelAudit: completeAuditData?.expenseAudit?.isLineLevelAudit || false,
        auditorAlias:
          profile?.data?.userPrincipalName?.substring(0, profile?.data?.userPrincipalName?.indexOf('@')) || null,
        companyCode: expenseData?.header?.companyCode || null,
        countryCode: expenseData?.header?.countryCode || null,
        countryName: expenseData?.header?.countryName || null,
        expenseApprovedDate: expenseData?.header?.dateApproved || null,
        expenseApproverAlias: expenseData?.header?.manager?.alias || null,
        expenseEmployeeAlias: expenseData?.header?.employee?.alias || null,
        expenseEmployeeName: expenseData?.header?.employee?.name || null,
        expenseSubmitterAlias: expenseData?.header?.submitter?.alias || null,
        expenseReportName: expenseData?.header?.reportName || null,
        expenseTool: expenseData.header.tool,
        expenseSubmittedDate: expenseData.header.dateSubmitted,
        lastUpdatedDate: todayDate,
        auditLines: completeAuditData?.expenseAudit?.auditLines || [],
      },
      partitionKey,
      id: expenseData?.header?.reportId,
    };

    yield put(updatePanelState(false));
    const httpClient: IHttpClient = yield getContext('httpClient');
    yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/auditsubmission`,
      method: 'POST',
      resource: __RESOURCE_URL__,
      data: payload,
    });
    yield put(submitAuditSuccess(APP_MESSAGES.AUDIT_UPDATE_SUCCESS));
    yield put(setOpenAuditSubmitDialog(true));
  } catch (error) {
    yield put(updatePanelState(false));
    yield put(submitAuditFailed(APP_MESSAGES.AUDIT_UPDATE_FAILURE));
    yield put(setOpenAuditSubmitDialog(true));
  }
}

function* submitLineAudit(action: ISubmitLineAuditAction) {
  try {
    const { auditActionsData } = action;
    const { expenseData, profile, completeAuditData } = yield select(expenseAuditDataSelector);
    const todayDate = new Date().toISOString();
    const partitionKey = completeAuditData.partitionKey;

    // pull and update the line item being audited
    const lineLevelBeingAudited = auditActionsData.lineLevelBeingAudited;
    const allLineItems = completeAuditData?.expenseAudit?.auditLines;
    const lineItemIndexBeingAudited = allLineItems.findIndex(
      (lineItem: any) => lineItem.lineItemId === lineLevelBeingAudited.lineItemId
    );
    if (lineItemIndexBeingAudited !== -1) {
      const updatedLineItem = {
        lineItemId: lineLevelBeingAudited.lineItemId,
        transactionId: lineLevelBeingAudited.lineTransactionId,
        lastupdateDate: lineLevelBeingAudited.lineLastUpdateDate,
        auditStatus: auditActionsData.status,
        auditConclusion: auditActionsData.conclusion,
        auditFeedback: auditActionsData.feedback == null ? '' : auditActionsData.feedback,
        recoveryAmountUSD: auditActionsData.recoveryAmountUsd,
        auditInitialReasons: lineLevelBeingAudited.lineAuditInitialReasons.split(', '),
        auditFinalReasons: auditActionsData.auditFinalReasons,
        lineItemScore: lineLevelBeingAudited.lineRiskScore,
        lineItemFlag: lineLevelBeingAudited.lineRiskFlag,
        caseLogs:
          auditActionsData?.auditComments == null
            ? [...(lineLevelBeingAudited.lineCaseLog || [])]
            : [
                ...(lineLevelBeingAudited.lineCaseLog || []),
                {
                  auditorFirstName: profile?.data?.givenName || null,
                  auditorLastName: profile?.data?.surname || null,
                  createdBy: profile?.data?.mail || null,
                  loggedDate: todayDate,
                  auditorComments: auditActionsData.auditComments || '',
                },
              ],
        moduleIndicators: lineLevelBeingAudited.lineModuleScores,
        auditorAlias:
          profile?.data?.userPrincipalName?.substring(0, profile?.data?.userPrincipalName?.indexOf('@')) || null,
      };
      allLineItems.splice(lineItemIndexBeingAudited, 1, updatedLineItem);
    }

    const existingConclusions = completeAuditData?.expenseAudit?.conclusion.split(', ');
    // assemble payload
    const payload = {
      expenseAudit: {
        expenseReportId: expenseData?.header?.reportId,
        createdDate: completeAuditData?.expenseAudit?.createdDate || null,
        auditInitialReasons: completeAuditData?.expenseAudit?.auditInitialReasons || null,
        auditFinalReasons:
          Array.from(
            new Set([...auditActionsData?.auditFinalReasons, ...completeAuditData?.expenseAudit?.auditFinalReasons])
          ) || [],
        status: completeAuditData?.expenseAudit?.status || null,
        conclusion: Array.from(new Set([auditActionsData?.conclusion, ...existingConclusions])).join(', ') || null,
        recoveryAmountUsd:
          parseFloat(completeAuditData?.expenseAudit?.recoveryAmountUsd) +
            parseFloat(auditActionsData?.recoveryAmountUsd) || 0.0,
        crmCode: auditActionsData?.crmCode || null,
        caseLogs:
          auditActionsData?.auditComments == null
            ? [...(completeAuditData?.expenseAudit?.caseLogs || [])]
            : [
                ...(completeAuditData?.expenseAudit?.caseLogs || []),
                {
                  auditorFirstName: profile?.data?.givenName || null,
                  auditorLastName: profile?.data?.surname || null,
                  createdBy: profile?.data?.mail || null,
                  loggedDate: todayDate,
                  auditorComments: auditActionsData.auditComments || '',
                },
              ],
        anomalyScore: completeAuditData?.expenseAudit?.anomalyScore || null,
        anomalyFlag: completeAuditData?.expenseAudit?.anomalyFlag || null,
        isLineLevelAudit: completeAuditData?.expenseAudit?.isLineLevelAudit || null,
        auditorAlias:
          profile?.data?.userPrincipalName?.substring(0, profile?.data?.userPrincipalName?.indexOf('@')) || null,
        companyCode: expenseData?.header?.companyCode || null,
        countryCode: expenseData?.header?.countryCode || null,
        countryName: expenseData?.header?.countryName || null,
        expenseApprovedDate: expenseData?.header?.dateApproved || null,
        expenseApproverAlias: expenseData?.header?.manager?.alias || null,
        expenseEmployeeAlias: expenseData?.header?.employee?.alias || null,
        expenseEmployeeName: expenseData?.header?.employee?.name || null,
        expenseSubmitterAlias: expenseData?.header?.submitter?.alias || null,
        expenseReportName: expenseData?.header?.reportName || null,
        expenseTool: expenseData.header.tool,
        expenseSubmittedDate: expenseData.header.dateSubmitted,
        lastUpdatedDate: todayDate,
        auditLines: allLineItems || [],
      },
      partitionKey,
      id: expenseData?.header?.reportId,
    };

    yield put(updatePanelState(false));
    const httpClient: IHttpClient = yield getContext('httpClient');
    yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/auditsubmission`,
      method: 'POST',
      resource: __RESOURCE_URL__,
      data: payload,
    });
    yield put(submitLineAuditSuccess(APP_MESSAGES.AUDIT_UPDATE_SUCCESS));
    yield put(setOpenAuditSubmitDialog(true));
  } catch (error) {
    yield put(updatePanelState(false));
    yield put(submitLineAuditFailed(APP_MESSAGES.AUDIT_UPDATE_FAILURE));
    yield put(setOpenAuditSubmitDialog(true));
  }
}

function* fetchExpenseData(action: IFetchExpenseDataAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const response: any = yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/ExpenseSearch?reportId=${action.reportId}`,
      resource: __RESOURCE_URL__,
    });
    yield put(setExpenseData(response.data));
    try {
      const userAlias = response.data?.header?.submitter?.alias;
      const { data: userImage }: IHttpClientRequest = yield call([httpClient, httpClient.request], {
        url: `${__GRAPH_BASE_URL__}users/${convertToUPN(userAlias)}/photos/96x96/$value`,
        resource: __GRAPH_RESOURCE_URL__,
        responseType: 'arraybuffer',
      });
      let charConversion = '';
      const byteArrayCopy = new Uint8Array(userImage);
      for (let i = 0; i < byteArrayCopy.byteLength; i++) {
        charConversion += String.fromCharCode(byteArrayCopy[i]);
      }

      yield put(receiveUserImage(charConversion));
    } catch (errorResponse) {
      const error = errorResponse.data ?? errorResponse;
      yield put(failedUserImage(error.message ? error.message : error));
    }
  } catch (error) {
    yield put(updatePanelState(false));
    yield put(setExpenseData({}, `${APP_MESSAGES.EXPENSE_REPORT_NOT_FOUND_FOR} ${action.reportId}.`));
  }
}

function* fetchCompleteAuditData(action: IFetchCompleteAuditDataAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const response: any = yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/AuditSearch?reportId=${action.reportId}`,
      resource: __RESOURCE_URL__,
    });

    // line level audit
    if (response?.data?.expenseAudit?.isLineLevelAudit) {
      yield put(setIsLineLevelAudit(true));
    } else {
      yield put(setIsLineLevelAudit(false));
    }

    yield put(setCompleteAuditData(response.data));
  } catch (error) {
    yield put(setCompleteAuditData({}));
  }
}

function* fetchAllDocuments(action: IRequestAllDocumentsAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    for (let attachment of action.attachmentArray) {
      try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const fileBytes: any = yield call([httpClient, httpClient.request], {
          url: `${__API_BASE_URL__}/ExpenseSearch/receipts?receiptId=${attachment.id}`,
          resource: __RESOURCE_URL__,
          responseType: 'arraybuffer',
        });

        const id = action.actionId;
        if (id.includes('download')) {
          let charConversion = '';
          const byteArrayCopy = new Uint8Array(fileBytes.data);
          for (let i = 0; i < byteArrayCopy.byteLength; i++) {
            charConversion += String.fromCharCode(byteArrayCopy[i]);
          }

          let file = Buffer.from(charConversion, 'base64');
          const blob = new Blob([file], { type: 'application/octet-stream' });
          const link = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          link.setAttribute('target', '_blank');
          link.setAttribute('href', objectUrl);
          link.setAttribute('download', attachment.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        yield put(failedAllDocumentDownload(error.message ? error.message : 'Unable to download attachments'));
      }
    }
    yield put(requestAllDocumentsEnd());
  } catch (errorResponse) {
    const error = errorResponse.data ?? errorResponse;
    yield put(failedAllDocumentDownload(error.message ? error.message : 'Unable to download attachments'));
  }
}

function* fetchDocument(action: IRequestDocumentAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const fileBytes: any = yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/ExpenseSearch/receipts?receiptId=${action.attachmentId}`,
      resource: __RESOURCE_URL__,
      responseType: 'arraybuffer',
    });

    yield put(requestDocumentEnd());
    const id = action.actionId;
    if (id.includes('download')) {
      let charConversion = '';
      const byteArrayCopy = new Uint8Array(fileBytes.data);
      for (let i = 0; i < byteArrayCopy.byteLength; i++) {
        charConversion += String.fromCharCode(byteArrayCopy[i]);
      }

      let file = Buffer.from(charConversion, 'base64');
      const blob = new Blob([file], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.setAttribute('target', '_blank');
      link.setAttribute('href', objectUrl);
      link.setAttribute('download', action.attachmentName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (errorResponse) {
    const error = errorResponse.data ?? errorResponse;
    yield put(failedDocumentDownload(error.message ? error.message : 'Unable to download receipt'));
  }
}

function* fetchDocumentPreview(action: IRequestDocumentPreviewAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const fileBytes: any = yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/ExpenseSearch/receipts?receiptId=${action.attachmentId}`,
      resource: __RESOURCE_URL__,
      responseType: 'arraybuffer',
    });

    let charConversion = '';
    const byteArrayCopy = new Uint8Array(fileBytes.data);
    for (let i = 0; i < byteArrayCopy.byteLength; i++) {
      charConversion += String.fromCharCode(byteArrayCopy[i]);
    }
    yield put(receiveDocumentPreview(charConversion));
  } catch (error) {
    console.error(error);
    yield put(failedDocumentPreview(error.message ? error.message : 'Unable to preview document'));
  }
}

function* fetchAllUserRoles(action: IRequestAllUserRolesAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const response: any = yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/AuditRoles/userRole`,
      resource: __RESOURCE_URL__,
    });
    yield put(receiveAllUserRoles(response.data));
  } catch (errorResponse) {
    const error = errorResponse.data ?? errorResponse;
    yield put(failedAllUserRoles(error.message ? error.message : 'Unable to get user roles'));
  }
}

function* updateUserRole(action: IUpdateUserRoleAction): IterableIterator<SimpleEffect<{}, {}>> {
  try {
    yield put(updatePanelState(false));
    const httpClient: IHttpClient = yield getContext('httpClient');
    yield call([httpClient, httpClient.request], {
      url: `${__API_BASE_URL__}/AuditRoles/userRole/update?isDelete=${action.isDeleteRequest}`,
      method: 'POST',
      resource: __RESOURCE_URL__,
      data: action.userRole,
    });
    yield put(updateUserRoleSuccess('success'));
    yield put(setUserChangeData(true, action.isDeleteRequest, false));
  } catch (errorResponse) {
    const error = errorResponse.data ?? errorResponse;
    yield put(failedUpdateUserRole(error.message ? error.message : 'Unable to get update user role'));
    yield put(setUserChangeData(true, action.isDeleteRequest, true));
  }
}

export function* expenseSagas(): IterableIterator<{}> {
  yield all([
    takeLatest(ExpenseAuditActionType.FETCH_AUDIT_ATTRIBUTE_OPTIONS, fetchAuditAttributeOptions),
    takeLatest(ExpenseAuditActionType.FETCH_AUDIT_STATUS, fetchAuditStatus),
    takeLatest(ExpenseAuditActionType.REQUEST_MY_PROFILE, fetchProfile),
    takeLatest(ExpenseAuditActionType.SUBMIT_AUDIT, submitAudit),
    takeLatest(ExpenseAuditActionType.SUBMIT_LINE_AUDIT, submitLineAudit),
    takeLatest(ExpenseAuditActionType.FETCH_EXPENSE_DATA, fetchExpenseData),
    takeLatest(ExpenseAuditActionType.FETCH_COMPLETE_AUDIT_DATA, fetchCompleteAuditData),
    takeLatest(ExpenseAuditActionType.REQUEST_DOCUMENT_PREVIEW, fetchDocumentPreview),
    takeLatest(ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS, fetchAllDocuments),
    takeLatest(ExpenseAuditActionType.REQUEST_DOCUMENT, fetchDocument),
    takeLatest(ExpenseAuditActionType.REQUEST_ALL_USER_ROLES, fetchAllUserRoles),
    takeLatest(ExpenseAuditActionType.UPDATE_USER_ROLE, updateUserRole),
  ]);
}
