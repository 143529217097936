export const DetailActionIcon = {
    float: 'right',
    marginRight: '8px',
    cursor: 'pointer',
    fontSize: '12pt',
};

export const tooltipCalloutProps = { gapSpace: 0 };
export const tooltipHostContainer = {
    root: {
        display: 'inline-block',
        float: 'right',
    },
};
