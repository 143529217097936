import * as React from 'react';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import { ExpensePageMainContainer } from '../../Shared/Components/Card';
import {
  ExpenseAuditReducerName,
  expenseAuditReducer,
  ExpenseAuditInitialState,
} from '../../Shared/Store/ExpenseAudit.reducer';
import { IExpenseAppState } from '../../Shared/Store/ExpenseAudit.types';
import { expenseSagas } from '../../Shared/Store/Expense.sagas';
import {
  setOpenAuditSubmitDialog,
  updatePanelState,
  fetchExpenseData,
  fetchAuditAttributeOptions,
  fetchCompleteAuditData,
} from '../../Shared/Store/ExpenseAudit.actions';
import { Reducer } from 'redux';
import { DetailCardContainer } from '../../Shared/Components/Card';
import * as _ from 'lodash';
import { DetailsPanel } from './DetailsPanel';
import { FLYOUT_VIEW } from '../../Shared/SharedConstants';
import ErrorView from '../../Shared/Components/MessageBars/ErrorView';
import SuccessView from '../../Shared/Components/MessageBars/SuccessViewBar';
import ExpenseAuditSearchForm from './ExpenseAuditSearchFormAdaptive';
import { DetailsAdaptive } from './DetailsAdaptive';
import { Dialog, DialogType, DialogFooter, IDialogContentProps, PrimaryButton, IStackTokens, Stack, Spinner } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';

interface IExpenseAuditPageProps {
  windowHeight: number;
  windowWidth: number;
}

function ExpenseAuditPage(props: IExpenseAuditPageProps): React.ReactElement {
  useDynamicReducer(ExpenseAuditReducerName, expenseAuditReducer as Reducer, [expenseSagas]);
  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const {
    isPanelOpen,
    detailsDefaultView,
    maxDetailsScreen,
    auditAttributeOptionsLoadError,
    expenseSearchErrorMessage,
    isAuthorized,
    isLoading,
    profileHasError,
    profileErrorMessage,
    submitSuccessMessage,
    postActionErrorMessage,
    openAuditSubmitDialog,
  } = useSelector((state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState);

  const initialDialogProps: IDialogContentProps = {
    type: DialogType.normal,
    title: '',
    subText: ''
  };

  const [dialogContentProps, setDialogContentProps] = React.useState<IDialogContentProps>(initialDialogProps);

  React.useEffect(() => {
    if (submitSuccessMessage) {
      setDialogContentProps({
        type: DialogType.normal,
        title: 'Audit update Succeeded',
        subText: submitSuccessMessage
      });
    } else if (postActionErrorMessage) {
      setDialogContentProps({
        type: DialogType.normal,
        title: 'Audit update Failed',
        subText: postActionErrorMessage
      });
    }
  }, [openAuditSubmitDialog, submitSuccessMessage, postActionErrorMessage]);

  const openDetails = (searchData: string) => {
    dispatch(fetchExpenseData(searchData));
    dispatch(fetchCompleteAuditData(searchData));
    dispatch(fetchAuditAttributeOptions());
    dispatch(updatePanelState(true));
  };
  const stackTokens: IStackTokens = { childrenGap: 24, padding: '20px 10px' };
  if (isLoading) {
    return (
      <Stack tokens={stackTokens} verticalFill={true}>
        <Styled.SpinnerContainer>
          <Spinner label="Please wait loading data..." />
        </Styled.SpinnerContainer>
      </Stack>
    );
  }
  if (profileHasError) {
    return (
      <div>
        <Styled.InnerPageContainer>
          <ErrorView errorMessage={profileErrorMessage}></ErrorView>
        </Styled.InnerPageContainer>
      </div>
    );
  }
  if (!isAuthorized) {
    return (
      <Styled.InnerPageContainer>
        <SuccessView successMessage={`You are not authorized to access this audit tool.`}></SuccessView>
      </Styled.InnerPageContainer>
    );
  }

  const errorMessageToDisplay = expenseSearchErrorMessage || auditAttributeOptionsLoadError || postActionErrorMessage;

  return (
    <div>
      <Dialog
          hidden={!openAuditSubmitDialog}
          dialogContentProps={dialogContentProps}
              >
        <DialogFooter>
          <PrimaryButton onClick={() => dispatch(setOpenAuditSubmitDialog(false))} text="Done" />
        </DialogFooter>
      </Dialog>
      <Styled.InnerPageContainer>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div
              className={
                'ms-Grid-col ' +
                (isPanelOpen && detailsDefaultView !== FLYOUT_VIEW
                  ? maxDetailsScreen
                    ? ' ms-sm4 ms-xl4 ms-xxl4 ms-xxxl4 ms-hiddenLgDown horizontal-separator'
                    : ' ms-sm7 ms-hiddenLgDown horizontal-separator'
                  : ' ms-sm12 ')
              }
                      >
              {errorMessageToDisplay && <ErrorView errorMessage={errorMessageToDisplay} />}
              <ExpensePageMainContainer
                winHeight={props.windowHeight}
                className="scroll-hidden v-scroll-auto custom-scrollbar"
              >
                <ExpenseAuditSearchForm onSearch={openDetails} />
              </ExpensePageMainContainer>
            </div>
            {isPanelOpen && detailsDefaultView !== FLYOUT_VIEW && (
              <div
                className={
                  ' ms-Grid-col ms-sm12 ms-md12 ms-lg12' + (maxDetailsScreen ? ' ms-xl8 ms-xxl8 ms-xxxl8' : ' ms-xl5')
                }
              >
                <DetailCardContainer
                  winHeight={props.windowHeight}
                  className="scroll-hidden v-scroll-auto custom-scrollbar"
                >
                  <DetailsAdaptive windowHeight={props.windowHeight} windowWidth={props.windowWidth} />
                </DetailCardContainer>
              </div>
            )}
            {detailsDefaultView === FLYOUT_VIEW && <DetailsPanel />}
          </div>
        </div>
      </Styled.InnerPageContainer>
    </div>
  );
}

const connected = withContext(ExpenseAuditPage);
export { connected as ExpenseAuditPage };
