export default {
    "type": "AdaptiveCard",
    "version": "1.5",
    "fallbackText": "This device does not support yet, please upgrade your outlook version",
    "body": [
        {
            "type": "ColumnSet",
            "height": "stretch",
            "columns": [
                {
                    "type": "Column",
                    "width": 1,
                    "items": [
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Status",
                            "id": "status",
                            "isMultiSelect": false,
                            "isRequired": true,
                            "errorMessage": "Please fill out the Audit Status",
                            "choices": "${auditAttributeOptions.auditStatus}",
                            "value": "${expenseAuditData.auditStatusHeader}"
                        },
                        {
                            "type": "Input.Text",
                            "label": "CRM Ticket",
                            "id":"crmCode",
                            "isMultiSelect": false,
                            "value": "${expenseAuditData.crmCode}"
                        },   
                        {
                            "type": "Input.ChoiceSet",
                            "label": "Audit Conclusion",
                            "id": "conclusion",
                            "isMultiSelect": false,
                            "choices": "${auditAttributeOptions.auditAction}",
                            "value": "${expenseAuditData.auditConclusionHeader}"
                        },
                        {
                            "type": "Input.Number",
                            "label": "Recovery Amount USD",
                            "id": "recoveryAmountUsd",
                            "isMultiSelect": false,
                            "value": "${expenseAuditData.recoveryAmountUsdHeader}"
                        },
                        {
                            "type": "TextBlock",
                            "text": "Audit Initial Reasons",
                            "horizontalAlignment": "Left"
                        },
                        {
                            "id": "auditInitialReasons",
                            "type": "TextBlock",
                            "wrap": true,
                            "text": "${expenseAuditData.auditInitialReasonsHeader} ",
                            "horizontalAlignment": "Left"
                        }
                    ],
                    "minHeight": "200px"
                }
            ]
        },
        {
            "type": "Container",
            "style": "default",
            "items": [
                {
                    "type": "Input.ChoiceSet",
                    "label": "Audit Final Reasons",
                    "id": "auditFinalReasons",
                    "isMultiSelect": true,
                    "choices": "${auditAttributeOptions.auditReason}",
                    "value": "${expenseAuditData.auditFinalReasonsHeaderAdaptiveCard}",
                    "wrap": true
                }
            ]
        },
        {
            "type": "Container",
            "$when": "${count(expenseAuditData.caseLogsHeader) > 0}",
            "id": "dataToToggle",
            "padding": "Small",
            "items": [
                {
                    "type": "Container",
                    "items": [
                        {
                            "type": "TextBlock",
                            "text": "Audit Comments History",
                            "size": "medium",
                            "weight": "Bolder"
                        }
                    ]
                },
                {
                    "type": "Table",
                    "separator": true,
                    "firstRowAsHeaders": true,
                    "columns": [
                        {
                            "width": 1
                        },
                        {
                            "width": 5
                        },
                        {
                            "width": 2
                        }
                    ],
                    "rows": [
                        {
                            "type": "TableRow",
                            "cells": [
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Log Date"
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Audit Comment"
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "Created By"
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "$data": "${expenseAuditData.caseLogsHeader}",
                            "type": "TableRow",
                            "cells": [
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${formatDateTime(loggedDate, 'MM-dd')}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${auditorComments}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                },
                                {
                                    "type": "TableCell",
                                    "items": [
                                        {
                                            "type": "TextBlock",
                                            "text": "${createdBy}",
                                            "color": "Accent",
                                            "wrap": true
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            "minHeight": "150px",
            "additionalProperties": {
                "padding": "Default"
            },
            "internalID": {}
        },
        {
            "type": "Container",
            "items": [
                {
                    "type": "Input.Text",
                    "isMultiline": true,
                    "id": "auditComments",
                    "label": "Audit Comments",
                    "value": "",
                    "height": "stretch"
                }
            ],
            "minHeight": "250px"
        }
    ],
    "actions": [
        {
            "type": "Action.Submit",
            "title": "Submit",
            "style": "positive",
            "id": "ActionSubmit"
        },
        {
            "type": "Action.Submit",
            "title": "Cancel",
            "style": "destructive",
            "associatedInputs": "none",
            "id": "ActionShowAuditDetail"
        }
    ],
    "additionalProperties": {
        "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
        "correlationId": null,
        "constrainWidth": true,
        "hideOriginalBody": true,
        "padding": "None"
    },
    "internalID": {},
    "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
    "correlationId": null,
    "constrainWidth": true,
    "hideOriginalBody": true,
    "padding": "None",
    "height": "stretch"
};