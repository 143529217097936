import * as React from 'react';
import { Reducer } from 'redux';
import * as Styled from './DetailsButtonsStyled';
import {
  ExpenseAuditInitialState,
  expenseAuditReducer,
  ExpenseAuditReducerName,
} from '../../../Shared/Store/ExpenseAudit.reducer';
import { IExpenseAppState } from '../../../Shared/Store/ExpenseAudit.types';
import { ToggleDetailsScreenMax } from '../../../Shared/Store/ExpenseAudit.actions';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { TooltipHost, IconButton } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { expenseSagas } from '../../../Shared/Store/Expense.sagas';

function MaximizeButton(props: { callbackOnMaximizeToggle?(): void }): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ExpenseAuditReducerName, expenseAuditReducer as Reducer, [expenseSagas]);

  const { maxDetailsScreen } = useSelector(
    (state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState
  );

  const iconName = maxDetailsScreen ? 'ChromeRestore' : 'FullScreen';
  const title = maxDetailsScreen ? 'Restore' : 'Maximize';

  return (
    <TooltipHost content={`${title}`} calloutProps={Styled.tooltipCalloutProps} styles={Styled.tooltipHostContainer}>
      <IconButton
        className="ms-hiddenMdDown"
        iconProps={{ iconName }}
        ariaLabel={`Click here to ${title} the details screen`}
        style={Styled.DetailActionIcon}
        onClick={(): void => {
          dispatch(ToggleDetailsScreenMax());
          if (props.callbackOnMaximizeToggle) {
            props.callbackOnMaximizeToggle();
          }
        }}
      />
    </TooltipHost>
  );
}

const connected = withContext(MaximizeButton);
export { connected as MaximizeButton };
