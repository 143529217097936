import * as React from 'react';
import { CoherenceNav } from '@coherence-design-system/controls/lib/nav';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { INavProps } from './Nav.types';
import { useCoherenceNavGroups } from './useCoherenceNavGroups';

export function Nav(props: INavProps): React.ReactElement {
  const { groups, defaultIsNavCollapsed, onNavCollapsed, appName, ...otherProps } = props;
  const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const coherenceNavGroups = useCoherenceNavGroups(groups);

  return (
    <CoherenceNav
      {...otherProps}
      appName={appName || ''}
      groups={coherenceNavGroups}
      onNavCollapsed={onNavCollapsed}
      defaultIsNavCollapsed={defaultIsNavCollapsed === undefined ? true : defaultIsNavCollapsed}
      telemetryHook={telemetryClient}
    />
  );
}
