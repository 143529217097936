import { IStackItemStyles, IMessageBarStyles, IStackStyles, Text } from '@fluentui/react';
import { MessagingColors, TextColors } from '../../Shared/Styles/SharedColors';

export const SecondaryHeaderContainer = styled.div`
  height: 45px;
  padding-top: 48px;

  .ms-Button-menuIcon {
    color: rgb(0, 13, 23) !important;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 0px !important;
  }
`;

export const SummaryCountText = styled(Text).attrs({
  as: 'p',
})`
  color: ${TextColors.lightPrimary};
  font-weight: bold;
`;

export const SummaryCountLabelText = styled(Text).attrs({
  as: 'p',
})`
  color: ${TextColors.lightPrimary};
  font-weight: regular;
  padding-left: 5px;
`;

export const FailedCountText = styled(Text).attrs({
  as: 'p',
})`
  color: ${MessagingColors.errorBlockIcon};
  font-weight: bold;
`;

export const FailedCountLabelText = styled(Text).attrs({
  as: 'p',
})`
  color: ${MessagingColors.errorBlockIcon};
  padding-left: 5px;
`;

export const SummaryCountStyling: IStackItemStyles = {
  root: {
    width: 'max-content',
  },
};

export const PersonaDropdownStyles: IStackItemStyles = {
  root: {
    marginLeft: '2%',
  },
};

export const GroupAndFilterIconStackItemStyles: IStackItemStyles = {
  root: {
    width: 'max-content',
  },
};

export const DelegationBarStyles: IMessageBarStyles = {
  root: {
    paddingLeft: '1.5%',
    paddingTop: '0.25%',
    paddingBottom: '0.25%',
    backgroundColor: '#D0E7F8', // Coherence DefaultThemeColors.blue20
  },
};

export const SecondaryHeaderStackStyles: IStackStyles = {
  root: {
    height: '48px',
    background: '#e5e5e5',
    position: 'fixed',
    width: 'calc(100vw - 48px)',
    zIndex: 50,
  },
};

export const SecondaryHeaderIconStyling = {
  height: '100%',
};
