import * as React from 'react';
import { Stack, TextField, ChoiceGroup, IChoiceGroupOption, PrimaryButton, DefaultButton } from '@fluentui/react';
import * as Styled from './UpdateUserRoleModalStyling';
import { IUserRoleRequest } from '../../Shared/Store/ExpenseAudit.types';
import { updateUserRole } from '../../Shared/Store/ExpenseAudit.actions';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { CoherenceModal } from '@coherence-design-system/controls';

type IUpdateUserRoleModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  userToUpdate?: IUserRoleRequest;
};

function UpdateUserRoleModal(props: IUpdateUserRoleModalProps): React.ReactElement {
  const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const [userAlias, setUserAlias] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(null);
  const [auditRole, setUserRole] = React.useState(null);

  React.useEffect(() => {
    if (props.userToUpdate) {
      setUserAlias(props.userToUpdate.userAlias);
      setIsAdmin(props.userToUpdate.isAdmin);
      setUserRole(props.userToUpdate.auditRole);
    }
  }, [props.userToUpdate]);

  const clearAllFields = () => {
    setUserAlias('');
    setIsAdmin(null);
    setUserRole(null);
  };

  const closeModal = () => {
    clearAllFields();
    props.onDismiss();
  };

  const onSubmitClicked = (): void => {
    // create the request
    const submitRequest: IUserRoleRequest = {
      userAlias: userAlias,
      isAdmin: isAdmin,
      auditRole: auditRole,
    };
    dispatch(updateUserRole(submitRequest, false));
    closeModal();
  };

  const checkRequiredFieldsFilled = () => {
    if (userAlias == null || userAlias == '' || isAdmin == null || auditRole == null) {
      return true;
    }
    return false;
  };

  const onRenderFooter = () => {
    return (
      <div>
        <Stack horizontal styles={Styled.ModalButtonGroup} tokens={Styled.ModalButtonGroupStackTokens}>
          <Stack.Item>
            <PrimaryButton
              text={props.userToUpdate ? 'Update User' : 'Add User'}
              title={props.userToUpdate ? 'Update User' : 'Add User'}
              onClick={onSubmitClicked}
              disabled={checkRequiredFieldsFilled()}
            />
          </Stack.Item>
          <Stack.Item>
            <DefaultButton text="Cancel" title="Cancel" onClick={closeModal} />
          </Stack.Item>
        </Stack>
      </div>
    );
  };

  const onUserEmailAliasChange = (e: any, value: string) => {
    setUserAlias(value);
  };

  const getErrorMessage = (value: string): string => {
    var isValid = String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    return isValid ? '' : 'Must input a correctly formatted email address';
  };

  const userRoleOptions: IChoiceGroupOption[] = [
    {
      key: 'Reviewer',
      text: 'Reviewer (read access only)',
    },
    {
      key: 'Auditor',
      text: 'Auditor (read and write access)',
    },
  ];

  const onUserRoleChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    if (option?.key == 'Reviewer') {
      setUserRole('Reviewer');
    } else if (option?.key == 'Auditor') {
      setUserRole('Auditor');
    }
  };

  const isAdminOptions: IChoiceGroupOption[] = [
    {
      key: 'true',
      text: 'Yes',
    },
    {
      key: 'false',
      text: 'No',
    },
  ];

  const onIsAdminChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    if (option?.key == 'true') {
      setIsAdmin(true);
    } else if (option?.key == 'false') {
      setIsAdmin(false);
    }
  };

  const getModalContent = () => {
    return (
      <div>
        <Stack styles={Styled.AddNewUserStackStyles} tokens={Styled.AddNewUserStackTokens}>
          <Stack.Item>
            <TextField
              label="User Email Alias"
              value={userAlias}
              required
              onChange={onUserEmailAliasChange}
              onGetErrorMessage={getErrorMessage}
              validateOnFocusOut
            />
          </Stack.Item>
          <Stack.Item>
            <ChoiceGroup
              label="Role"
              required
              options={userRoleOptions}
              onChange={onUserRoleChange}
              selectedKey={auditRole}
            />
          </Stack.Item>
          <Stack.Item>
            <ChoiceGroup
              label="Do you want to give this user admin access?"
              required
              options={isAdminOptions}
              onChange={onIsAdminChange}
              selectedKey={isAdmin != null ? isAdmin.toString() : null}
            />
          </Stack.Item>
        </Stack>
      </div>
    );
  };
  return (
    <CoherenceModal
      isOpen={props.isOpen}
      modalWidth={'small'}
      height={'fixed'}
      onDismiss={closeModal}
      title={props.userToUpdate ? 'Update User Role' : 'Add a New User Role'}
      onRenderFooter={onRenderFooter}
    >
      {getModalContent()}
    </CoherenceModal>
  );
}

const connected = withContext(UpdateUserRoleModal);
export { connected as UpdateUserRoleModal };
