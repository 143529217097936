import * as AC from 'adaptivecards';

export class MultiChoiceInput extends AC.Input {
    static readonly JsonTypeName = "MultiChoiceInput";
    //#region Schema

    static readonly valueProperty = new AC.StringProperty(AC.Versions.v1_0, "value");
    static readonly choicesProperty = new AC.SerializableObjectCollectionProperty(AC.Versions.v1_0, "choices", AC.Choice);
    static readonly styleProperty = new AC.ValueSetProperty(
        AC. Versions.v1_0,
        "style",
        [
            { value: "compact" },
            { value: "expanded" }
        ],
        "compact");
    static readonly isMultiSelectProperty = new AC.BoolProperty(AC.Versions.v1_0, "isMultiSelect", false);
    static readonly placeholderProperty = new AC.StringProperty(AC.Versions.v1_0, "placeholder");
    static readonly wrapProperty = new AC.BoolProperty(AC.Versions.v1_2, "wrap", false);

    @AC.property(MultiChoiceInput.valueProperty)
    defaultValue?: string;

    @AC.property(MultiChoiceInput.styleProperty)
    style?: "compact" | "expanded";

    get isCompact(): boolean {
        return this.style !== "expanded";
    }

    set isCompact(value: boolean) {
        this.style = value ? undefined : "expanded";
    }

    @AC.property(MultiChoiceInput.isMultiSelectProperty)
    isMultiSelect: boolean = false;

    @AC.property(MultiChoiceInput.placeholderProperty)
    placeholder?: string;

    @AC.property(MultiChoiceInput.wrapProperty)
    wrap: boolean = false;

    @AC.property(MultiChoiceInput.choicesProperty)
    choices: Choice[] = [];

    //#endregion

    private static uniqueCategoryCounter = 0;

    private static getUniqueCategoryName(): string {
        let uniqueCategoryName = "__ac-category" + MultiChoiceInput.uniqueCategoryCounter;

        MultiChoiceInput.uniqueCategoryCounter++;

        return uniqueCategoryName;
    }

    private _uniqueCategoryName: string;
    private _selectElement: HTMLSelectElement;
    private _toggleInputs: HTMLInputElement[] | undefined;
    private _labels: Array<HTMLElement | undefined>;

    // Make sure `aria-current` is applied to the currently-selected item
    private internalApplyAriaCurrent(): void {
        const options = this._selectElement.options;

        if (options) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                    options[i].setAttribute("aria-current", "true");
                }
                else {
                    options[i].removeAttribute("aria-current");
                }
            }
        }
    }

    private renderCompoundInput(cssClassName: string, type: "checkbox" | "radio", defaultValues: string[] | undefined): HTMLElement {
        let element = document.createElement("div");
        element.className = this.hostConfig.makeCssClassName("ac-input", cssClassName);
        element.style.width = "100%";

        this._toggleInputs = [];
        this._labels = [];

        for (let choice of this.choices) {
            let input = document.createElement("input");
            input.id = AC.generateUniqueId();
            input.type = type;
            input.style.margin = "0";
            input.style.display = "inline-block";
            input.style.verticalAlign = "middle";
            input.style.flex = "0 0 auto";
            input.name = this.id ? this.id : this._uniqueCategoryName;

            if (this.isRequired) {
                input.setAttribute("aria-required", "true");
            }

            if (choice.value) {
                input.value = choice.value;
            }

            if (choice.title) {
                input.setAttribute("aria-label", choice.title);
            }

            if (defaultValues && choice.value) {
                if (defaultValues.indexOf(choice.value) >= 0) {
                    input.checked = true;
                }
            }

            input.onchange = () => { this.valueChanged(); }

            this._toggleInputs.push(input);

            let compoundInput = document.createElement("div");
            compoundInput.style.display = "flex";
            compoundInput.style.alignItems = "center";

            AC.appendChild(compoundInput, input);

            let label = new AC.TextBlock();
            label.setParent(this);
            label.forElementId = input.id;
            label.hostConfig = this.hostConfig;
            label.text = choice.title ? choice.title : "Choice " + this._toggleInputs.length;
            label.useMarkdown = AC.GlobalSettings.useMarkdownInRadioButtonAndCheckbox;
            label.wrap = this.wrap;

            let labelElement = label.render();

            this._labels.push(labelElement);

            if (labelElement) {
                labelElement.id = AC.generateUniqueId();
                labelElement.style.display = "inline-block";
                labelElement.style.flex = "1 1 auto";
                labelElement.style.marginLeft = "6px";
                labelElement.style.verticalAlign = "middle";

                let spacerElement = document.createElement("div");
                spacerElement.style.width = "6px";

                AC.appendChild(compoundInput, spacerElement);
                AC.appendChild(compoundInput, labelElement);
            }

            AC.appendChild(element, compoundInput);
        }

        return element;
    }

    protected updateInputControlAriaLabelledBy() {
        if ((this.style === "expanded") && this._toggleInputs && this._labels) {
            let labelIds: string[] = this.getAllLabelIds();

            for (let i = 0; i < this._toggleInputs.length; i++) {
                let joinedLabelIds = labelIds.join(" ");
                let label = this._labels[i];

                if (label && label.id) {
                    joinedLabelIds += " " + label.id;
                }

                if (joinedLabelIds) {
                    this._toggleInputs[i].setAttribute("aria-labelledby", joinedLabelIds);
                }
                else {
                    this._toggleInputs[i].removeAttribute("aria-labelledby");
                }
            }
        }
        else {
            super.updateInputControlAriaLabelledBy();
        }
    }

    protected internalRender(): HTMLElement | undefined {
        this._uniqueCategoryName = MultiChoiceInput.getUniqueCategoryName();
        if (this.style === "expanded") {
            // Render as a series of radio buttons
            return this.renderCompoundInput(
                "ac-choiceSetInput-expanded",
                "radio",
                this.defaultValue ? [ this.defaultValue ] : undefined);
        }
        else {
            this._selectElement = document.createElement("select");
            this._selectElement.className = this.hostConfig.makeCssClassName("ac-input", "ac-multichoiceInput", "ac-choiceSetInput-compact");
            this._selectElement.style.width = "100%";
            if(this.isMultiSelect){
                this._selectElement.multiple = true;
            }

            const selectedOptions = this.defaultValue ? this.defaultValue.split(this.hostConfig.choiceSetInputValueSeparator): [];
            let option = document.createElement("option");
            option.selected = selectedOptions.length === 0;
            option.disabled = true;
            option.hidden = true;
            option.value = "";

            if (this.placeholder) {
                option.text = this.placeholder;
            }

            AC.appendChild(this._selectElement, option);

            for (let choice of this.choices) {
                let option = document.createElement("option");
                option.value = <string>choice.value;
                option.text = <string>choice.title;
                option.setAttribute("aria-label", <string>choice.title);

                if (selectedOptions.includes(choice.value)) {
                    option.selected = true;
                }

                AC.appendChild(this._selectElement, option);
            }

            this._selectElement.onchange = () => {
                this.internalApplyAriaCurrent();
                this.valueChanged();
            }

            this.internalApplyAriaCurrent();
            

            return this._selectElement;
        }
    }

    getJsonTypeName(): string {
        return "MultiChoiceInput";
    }

    focus() {
        if (this._toggleInputs && (this.isMultiSelect || this.style === "expanded")) {
            if (this._toggleInputs.length > 0) {
                this._toggleInputs[0].focus();
            }
        }
        else {
            super.focus();
        }
    }

    internalValidateProperties(context: AC.ValidationResults) {
        super.internalValidateProperties(context);

        if (this.choices.length == 0) {
            context.addFailure(
                this,
                AC.ValidationEvent.CollectionCantBeEmpty,
                AC.Strings.errors.choiceSetMustHaveAtLeastOneChoice());
        }

        for (let choice of this.choices) {
            if (!choice.title || !choice.value) {
                context.addFailure(
                    this,
                    AC.ValidationEvent.PropertyCantBeNull,
                    AC.Strings.errors.choiceSetChoicesMustHaveTitleAndValue());
            }
        }
    }

    isSet(): boolean {
        return this.value ? true : false;
    }

    get value(): string | string[] | undefined {
        if( this.isMultiSelect){
            if (this._selectElement.selectedIndex > 0) {
                return Array.from(this._selectElement.selectedOptions).map(opt=>opt.value);
            }
            return undefined;
        }
        else if (this.isCompact) {
            if (this._selectElement) {
                return this._selectElement.selectedIndex > 0 ? this._selectElement.value : undefined;
            }

            return undefined;
        }
        else {
            if (!this._toggleInputs || this._toggleInputs.length == 0) {
                return undefined;
            }

            for (let toggleInput of this._toggleInputs) {
                if (toggleInput.checked) {
                    return toggleInput.value;
                }
            }

            return undefined;
        }
    }
}

