import * as React from 'react';
import * as Styled from './DetailsMessageBarsStyling';
import * as sanitizeHtml from 'sanitize-html';
import { MessageBar, MessageBarType, Stack, Link } from '@fluentui/react';

interface SuccessViewBarProps {
  successMessage: string;
  linkHref?: string;
  linkText?: string;
  closeMessage?: any;
}

const SuccessViewBar = ({
  successMessage = '',
  linkHref = null,
  linkText = null,
  closeMessage = null,
}: SuccessViewBarProps): JSX.Element => {
  const cleanSuccessMessage = sanitizeHtml(successMessage, {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  });
  return (
    <MessageBar
      messageBarType={MessageBarType.success}
      isMultiline={true}
      aria-label={'Success message'}
      onDismiss={closeMessage}
      dismissButtonAriaLabel={'Dismiss success message'}
    >
      <Stack tokens={Styled.OtherViewsStackTokensGap}>
        <Stack.Item>
          <div dangerouslySetInnerHTML={{ __html: cleanSuccessMessage }} />
        </Stack.Item>
        <Stack.Item>{linkHref && <Link href={linkHref}>{linkText}</Link>}</Stack.Item>
      </Stack>
    </MessageBar>
  );
};

export default SuccessViewBar;
