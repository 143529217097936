import { IStackStyles } from '@fluentui/react';

export const ButtonsStackStyles: IStackStyles = {
  root: {
    marginTop: '16px',
    marginBottom: '16px',
  },
};

export const LinkStackItemStyles: IStackStyles = {
  root: {
    marginTop: '8px',
    marginBottom: '8px',
  },
};
