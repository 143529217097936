import * as React from 'react';
import * as _ from 'lodash';
import {
  submitAudit,
  submitLineAudit,
  requestDocumentPreview,
  closeDocumentPreview,
  requestDocument,
  requestDocumentStart,
  requestAllDocumentsStart,
  requestAllDocuments,
  fetchCompleteAuditData,
  updatePanelState,
} from '../../Shared/Store/ExpenseAudit.actions';
import { Adaptive } from '../../Helpers/AdaptiveCardHelpers/Adaptive';
import AuditActionsAdaptiveCardConfig from './AuditActionsAdaptiveCardConfig';
import {
  IExpenseAppState,
  IAuditData,
  IAuditLine,
  IModuleScore,
  ICaseLog,
} from '../../Shared/Store/ExpenseAudit.types';
import { ExpenseAuditReducerName, ExpenseAuditInitialState } from '../../Shared/Store/ExpenseAudit.reducer';
import { mapExpenseStatusIcon, isMobileResolution } from '../../Helpers/sharedHelpers';
import { ApprovedIcon, DraftIcon, InReviewIcon, PendingIcon, RejectedIcon } from '../../Static/Icons';
import { CloseButton } from '../../Shared/Components/CloseButton';
import { BackButton } from './DetailsButton/BackButton';
import { MaximizeButton } from './DetailsButton/MaximizeButton';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import DocumentPreview from './DocumentPreview';
import ErrorView from '../../Shared/Components/MessageBars/ErrorView';
import SuccessView from '../../Shared/Components/MessageBars/SuccessView';
import { stockImage } from '../../Helpers/stockImage';
import { IDropdownOption, Stack, Spinner, IStackTokens, Modal, ContextualMenu, IconButton } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import DetailsAdaptiveCardConfig from './DetailsAdaptiveCardConfig';
import DetailsAdaptiveLineLevelCardConfig from './LineLevelAudits/DetailsAdaptiveLineLevelCardConfig';
import AuditActionsLineLevelAdaptiveCardConfig from './LineLevelAudits/AuditActionsLineLevelAdaptiveCardConfig';

const defaultExpenseAuditData: IAuditData = {
    auditConclusionHeader: "",
    auditInitialReasonsHeader: "",
    auditFinalReasonsHeader: "",
    crmCode: "",
    recoveryAmountUsdHeader: "",
    auditStatusHeader: "",
    auditLines: [],
    caseLogsHeader: [],
    auditFinalReasonsHeaderAdaptiveCard: "",
    auditRiskScoreHeader: 0,
    auditRiskLevelHeader: ""
};

interface IDetailsAdaptiveProps {
  windowHeight: number;
  windowWidth: number;
}

const previewFileTypes = ['bmp', 'png', 'jpg', 'jpeg', 'wmf', 'gif'];

function DetailsAdaptive(props: IDetailsAdaptiveProps): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const [shouldDisplayAuditActions, setDisplayAuditActions] = React.useState<boolean>(false);
  const [errorMessageToDisplay, setErrorMessageToDisplay] = React.useState<string>();
  const [expenseAuditData, setExpenseAuditData] = React.useState(defaultExpenseAuditData);
  const [detailsIndex, setDetailsIndex] = React.useState([]);
  const [selectedAttachmentID, setSelectedAttachmentID] = React.useState<string>(null);
  const [selectedAttachmentName, setSelectedAttachmentName] = React.useState<string>(null);
  const [attachmentsArray, setAttachmentsArray] = React.useState<any[]>(null);
  const [isModalExpanded, setIsModalExpanded] = React.useState<boolean>(true);
  const [actionCompleted, setActionCompleted] = React.useState<boolean>(false);
  const [lineLevelBeingAudited, setLineLevelBeingAudited] = React.useState<any>(null);

  const {
    expenseData,
    isExpenseSearchInProgress,
    isLoadingAuditAttributeOptions,
    auditAttributeOptions,
    isPreviewOpen,
    isLoadingPreview,
    documentPreview,
    auditAttributeOptionsLoadError,
    expenseSearchErrorMessage,
    isLoadingCompleteAuditData,
    completeAuditData,
    userImageHasError,
    userImage,
    isLoadingUserImage,
    footerHeight,
    panelWidth,
    documentPreviewHasError,
    documentPreviewErrorMessage,
    isModalPreviewOpen,
    isProcessingAction,
    isDocumentDownloading,
    isAllDocumentsDownloading,
    postActionErrorMessage,
    postActionHasError,
    documentDownloadHasError,
    documentDownloadErrorMessage,
    currentUserRole,
    isLineLevelAudit,
  } = useSelector((state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState);

  React.useEffect(() => {
    setLineLevelBeingAudited(null);
    setDisplayAuditActions(false);
    setErrorMessageToDisplay('');
    setDetailsIndex([]);
  }, [expenseData]);

  const setLineCaseLogs = (caseLogs: any): ICaseLog[] => {
    if (caseLogs !== null && caseLogs.length > 0) {
      return caseLogs.map((caseLogs: any) => ({
        auditorFirstName: caseLogs.auditorFirstName,
        auditorLastName: caseLogs.auditorLastName,
        createdBy: caseLogs.createdBy,
        loggedDate: caseLogs.loggedDate,
        auditorComments: caseLogs.auditorComments,
      }));
    } else {
      return [];
    }
  };

  const setLineModuleScores = (moduleScores: any): IModuleScore[] => {
    if (moduleScores !== null && moduleScores.length > 0) {
      return moduleScores.map((moduleIndicator: any) => ({
        moduleName: moduleIndicator.moduleName,
        moduleRiskIndicator: moduleIndicator.moduleRiskIndicator,
        moduleRiskScore: moduleIndicator.moduleRiskScore,
      }));
    } else {
      return [];
    }
  };

  const setAuditLines = (lines: any): IAuditLine[] => {
    if (lines !== null && lines.length > 0) {
      return lines.map((line: any) => ({
        lineItemId: line.lineItemId,
        lineTransactionId: line.transactionId,
        lineLastUpdateDate: line.lastupdateDate,
        lineAuditStatus: line.auditStatus == '' ? 'Open' : line.auditStatus,
        lineAuditConclusion: line.auditConclusion,
        lineAuditFeedback: line.auditFeedback == null ? '' : line.auditFeedback,
        lineRecoveryAmountUsd: line.recoveryAmountUSD == 0 ? '' : line.recoveryAmountUSD,
        lineAuditInitialReasons: (line.auditInitialReasons || []).join(', '),
        lineAuditFinalReasons: (line.auditFinalReasons || []).join(', '),
        lineFinalReasonsAdaptiveCard: (line.auditFinalReasons || []).join(','),
        lineRiskScore: line.lineItemScore,
        lineRiskFlag: line.lineItemFlag,
        lineModuleScores: setLineModuleScores(line.moduleIndicators),
        lineModuleScoresAdaptiveCard: setLineModuleScores(line.moduleIndicators).filter(
          (moduleIndicator) => moduleIndicator.moduleRiskIndicator !== 'Low'
        ),
        lineCaseLog: setLineCaseLogs(line.caseLogs),
      }));
    } else {
      return [];
    }
  };

  const setCaseLogs = (lines: IAuditLine[]): ICaseLog[] => {
    const caseLogs: ICaseLog[] = [];
    if (lines?.length > 0) {
      lines.forEach((line) => {
        caseLogs.push(...line.lineCaseLog);
      });
    }
    sortCaseLogs(caseLogs);
    return caseLogs;
  };

    const sortCaseLogs = (caseLogs: ICaseLog[]): ICaseLog[] => {
        if (caseLogs?.length > 0) {
            caseLogs.sort((a: ICaseLog, b: ICaseLog) => {
                const dateA = new Date(a.loggedDate);
                const dateB = new Date(b.loggedDate);
                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return caseLogs ?? [];
    }

    React.useEffect(()=>{
        const expenseAudit = completeAuditData?.expenseAudit;
        if (expenseAudit) {
            setExpenseAuditData({
                auditConclusionHeader: expenseAudit?.conclusion,
                auditInitialReasonsHeader: expenseAudit?.auditInitialReasons?.length > 0 ? (expenseAudit?.auditInitialReasons || []).join(", ") : "None",
                auditFinalReasonsHeader: (expenseAudit?.auditFinalReasons || []).join(", "),
                crmCode: expenseAudit?.crmCode ? expenseAudit.crmCode : '',
                recoveryAmountUsdHeader:  expenseAudit?.recoveryAmountUsd == 0 ? "" : expenseAudit?.recoveryAmountUsd,
                auditStatusHeader: expenseAudit?.status == "" ? "Open" : expenseAudit?.status,
                auditLines: setAuditLines(expenseAudit?.auditLines),
                caseLogsHeader: isLineLevelAudit ? setCaseLogs(setAuditLines(expenseAudit?.auditLines)) : sortCaseLogs(expenseAudit?.caseLogs),
                auditFinalReasonsHeaderAdaptiveCard: (expenseAudit?.auditFinalReasons || []).join(","),
                auditRiskScoreHeader: expenseAudit?.anomalyScore,
                auditRiskLevelHeader: expenseAudit?.anomalyFlag
            })
        } 
        else {
            setExpenseAuditData({
                ...defaultExpenseAuditData
            })
        }
    },[completeAuditData?.expenseAudit]);

  const validatePostData = (postData: any) => {
    let errorMessage = '';
    if (postData.status === 'Completed') {
      if (!postData.auditFinalReasons || !postData.conclusion) {
        errorMessage = 'Audit Conclusion and Audit Final Reason is mandatory when Audit status is Completed.';
      }
      if (postData.conclusion === 'None') {
        errorMessage = 'Audit Conclusion cannot be None When Audit Status is completed.';
      }
      setErrorMessageToDisplay(errorMessage);
      if (errorMessage === '') {
        return true;
      } else {
        return false;
      }
    } else {
      setErrorMessageToDisplay('');
      return true;
    }
  };

  const updatePayload = (template: any): void => {
    // make any necessary updates to the payload before passing it into Adaptive
    if (!shouldDisplayAuditActions) {
      template = replaceUserImage(template);
      template = checkAuditActionButton(template);
    }
    return template;
  };

  const checkAuditActionButton = (template: any): void => {
    // determine whether the audit action button should be enabled or not based on
    // user's permissions
    let jsonString = JSON.stringify(template);
    if (currentUserRole?.userRole?.write) {
      jsonString = jsonString.replace('#IsAuditActionEnabled#', 'true');
    } else {
      jsonString = jsonString.replace('#IsAuditActionEnabled#', 'false');
    }
    return JSON.parse(jsonString);
  };

  const replaceUserImage = (template: any): void => {
    if (!shouldDisplayAuditActions) {
      let jsonString = JSON.stringify(template);

      // if (!userImageHasError && userImage) {
      //     // replace with user image
      //     jsonString = jsonString.replace('#UserImage#', `data:image/png;base64,${userImage}`);
      // }
      // // image wasn't found
      // else {
      // replace with stock image
      // jsonString = jsonString.replace('#UserImage#', `data:image/png;base64,${stockImage}`);
      // }

      jsonString = jsonString.replace('#UserImage#', `data:image/png;base64,${stockImage}`);
      return JSON.parse(jsonString);
    } else {
      return template;
    }
  };

  const handleAttachmentSelect = (e: any, selection: IDropdownOption): void => {
    const selectedID = selection.key as string;
    const selectedName = selection.text as string;
    setSelectedAttachmentName(selectedName);
    setSelectedAttachmentID(selectedID);
    const isModal = !isMobileResolution(props.windowWidth);
    if (selectedID) {
      dispatch(requestDocumentPreview(selectedID, isModal));
    }
  };

  const handleDownload = (): void => {
    downloadSingleAttachment(selectedAttachmentName, selectedAttachmentID);
  };

  const downloadAllAttachments = (attachments: any) => {
    dispatch(requestAllDocumentsStart());
    dispatch(requestAllDocuments('download', attachments));
  };

  const downloadSingleAttachment = (attachmentName: string, attachmentID: string) => {
    setSelectedAttachmentName(attachmentName);
    setSelectedAttachmentID(attachmentID);

    dispatch(requestDocumentStart(attachmentName.split(' ')[1] ?? attachmentName));
    dispatch(requestDocument('download', attachmentName, attachmentID));
  };

  const handleAdaptiveCardSubmitAction = (id: string, data: any) => {
    // preview attachment(s)
    if (id.includes('preview')) {
      const attachmentID = data[0].id;
      const attachmentName = data[0].name;
      setSelectedAttachmentID(attachmentID);
      setSelectedAttachmentName(attachmentName);
      setAttachmentsArray(data);
      const isModal = !isMobileResolution(props.windowWidth);
      dispatch(requestDocumentPreview(attachmentID, isModal));
    }
    // download single attachment
    else if (id == 'downloadAnAttachment') {
      downloadSingleAttachment(data[0].name, data[0].id);
    }
    // download all attachments
    else if (id == 'downloadAllAttachments') {
      downloadAllAttachments(data);
    }
    // show the audit actions
    else if (id === 'ActionShowAuditAction') {
      if (isLineLevelAudit) {
        const updatedCRMcodeData = {
          ...expenseAuditData,
            crmCode: data?.crmCode ? data.crmCode : '',
        }
        setExpenseAuditData(updatedCRMcodeData)
        const auditLine = expenseAuditData.auditLines.find((auditLine) => auditLine.lineItemId === data[0].lineItemId);
        const expenseLine = expenseData?.lines?.find((expenseLine: any) => expenseLine?.lineItemId === data[0]?.lineItemId);
        const lineInfo: IAuditLine = { ...auditLine, showAuditFeedback: !!expenseLine?.transactionId };
        setLineLevelBeingAudited(lineInfo);
      }
      setDisplayAuditActions(true);
    }
    // show the audit details
    else if (id === 'ActionShowAuditDetail') {
      setErrorMessageToDisplay('');
      setLineLevelBeingAudited(null);
      setDisplayAuditActions(false);
    }
    // header level submit
    else if (id === 'ActionSubmit') {
      const postData = { ...data };
      const updatedExpenseData = {
        ...expenseAuditData,
        ...data,
      };
      updatedExpenseData.recoveryAmountUsd =
        updatedExpenseData.recoveryAmountUsd == 0 ? '' : Number(updatedExpenseData.recoveryAmountUsd);
      setExpenseAuditData(updatedExpenseData);
      if (validatePostData(postData)) {
        postData.auditFinalReasons = postData.auditFinalReasons ? postData.auditFinalReasons.split(',') : [];
        dispatch(submitAudit(postData));
        dispatch(fetchCompleteAuditData(expenseData?.header?.reportId));
        dispatch(updatePanelState(true));
      }
    }
    // line level submit
    else if (id === 'SubmitLineLevel') {
      const crmCode = expenseAuditData.crmCode;
      const postData = { ...data, crmCode, lineLevelBeingAudited };
      const updatedExpenseData = {
        ...expenseAuditData,
        ...data,
      };
      updatedExpenseData.recoveryAmountUsd =
        updatedExpenseData.recoveryAmountUsd == 0 ? '' : Number(updatedExpenseData.recoveryAmountUsd);
      setExpenseAuditData(updatedExpenseData);
      if (validatePostData(postData)) {
        postData.auditFinalReasons = postData.auditFinalReasons ? postData.auditFinalReasons.split(',') : [];
        dispatch(submitLineAudit(postData));
        dispatch(fetchCompleteAuditData(expenseData?.header?.reportId));
        dispatch(updatePanelState(true));
      }
    }
  };

  const renderFilePreview = (
    isModal?: boolean,
    modalWidth?: number,
    modalHeight?: number,
    isModalExpanded?: boolean
  ): JSX.Element => {
    const attachmentOptions: IDropdownOption[] = attachmentsArray.map((attachment: any) => {
      return { key: attachment.id, text: attachment.name };
    });

    return (
      <Stack tokens={Styled.DetailsFilePreviewStackTokens}>
        <Stack.Item>
          <DocumentPreview
            documentPreview={documentPreview}
            documentPreviewHasError={documentPreviewHasError}
            dropdownOnChange={handleAttachmentSelect}
            dropdownSelectedKey={selectedAttachmentID}
            dropdownOptions={attachmentOptions}
            previewContainerInitialWidth={isModal ? modalWidth : panelWidth} //provide height minus footer height
            footerHeight={footerHeight}
            handleDownloadClick={handleDownload}
            isModal={isModal}
            isModalExpanded={isModalExpanded}
            previewContainerInitialHeight={isModal ? modalHeight : null}
          />
        </Stack.Item>
        {documentPreviewHasError && documentPreviewErrorMessage && (
          <Stack.Item>
            <ErrorView errorMessage="No content available" failureType={'Document preview'} />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const detailCardHeaderActionBar = () => {
    return (
      <Stack.Item
        styles={{
          ...Styled.docPreviewHeaderBarStyles,
          root: {
            ...(Styled.docPreviewHeaderBarStyles.root as any),
            ...(Styled.StickyDetailsHeder.root as any),
          },
        }}
      >
        {isPreviewOpen && <BackButton></BackButton>}
        {!isPreviewOpen && <div></div>}
        <Stack style={{ flexFlow: 'row' }}>
          {isDocumentDownloading && adaptiveJSON && (
            <Stack.Item>
              <Spinner label={`Downloading ${selectedAttachmentName}`} labelPosition="left" style={{ margin: '5px' }} />
            </Stack.Item>
          )}
          {isAllDocumentsDownloading && adaptiveJSON && (
            <Stack.Item>
              <Spinner label={`Downloading all documents`} labelPosition="left" style={{ margin: '5px' }} />
            </Stack.Item>
          )}
          {documentDownloadHasError && documentDownloadErrorMessage && adaptiveJSON && (
            <Stack.Item styles={Styled.HeaderActionBarMessageStyle}>
              <Styled.ErrorText>Document download failed.</Styled.ErrorText>
            </Stack.Item>
          )}
          <Stack.Item
            styles={{
              root: {
                ...(Styled.StickyDetailsHederElement.root as any),
              },
            }}
          >
            <MaximizeButton />
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                ...(Styled.StickyDetailsHederElement.root as any),
              },
            }}
          >
            <CloseButton />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    );
  };
  const stackTokens: IStackTokens = { childrenGap: 24, padding: '15px 10px' };

  const handleActionResponse = () => {
    if (postActionHasError) {
      let errorMessage;
      try {
        const errorResponseObject = JSON.parse(postActionErrorMessage);
        errorMessage = errorResponseObject.ErrorMessage ?? postActionErrorMessage;
      } catch (ex) {
        errorMessage = postActionErrorMessage;
      }
      return <ErrorView errorMessage={errorMessage} failureType={'Submit audit'} />;
    } else {
      return <SuccessView successMessage="Success" />;
    }
  };

  if (isExpenseSearchInProgress || isLoadingAuditAttributeOptions || isLoadingCompleteAuditData || isLoadingUserImage) {
    return (
      <Stack tokens={stackTokens} verticalFill={true}>
        <Stack.Item>{detailCardHeaderActionBar()}</Stack.Item>
        <Styled.SpinnerContainer>
          <Spinner label="Loading Summary..." />
        </Styled.SpinnerContainer>
      </Stack>
    );
  } else if (auditAttributeOptionsLoadError || expenseSearchErrorMessage) {
    return null;
  }

  // determine which adaptive card to show
  const detailsAdaptiveCard = isLineLevelAudit ? DetailsAdaptiveLineLevelCardConfig : DetailsAdaptiveCardConfig;
  const auditActionsCard = isLineLevelAudit ? AuditActionsLineLevelAdaptiveCardConfig : AuditActionsAdaptiveCardConfig;
  const adaptiveCardConfig = shouldDisplayAuditActions ? auditActionsCard : detailsAdaptiveCard;

  const combineAuditAndExpenseLines = (expenseLines: any) => {
    const lines = expenseLines.map((line: any) => {
      return {
        ...line,
        glCode: line?.glCode?.toString(),
      };
    });

    const updatedLines = lines.map((line: any) => {
      const matchingLineItem = expenseAuditData.auditLines.find(
        (auditLine) => auditLine.lineItemId === line.lineItemId
      );

      if (matchingLineItem) {
        return { ...line, auditLine: matchingLineItem };
      }

      return line;
    });

    return updatedLines;
  };

  const updatedExpense = {
    ...expenseData,
    lines: combineAuditAndExpenseLines(expenseData.lines),
    header: {
      ...expenseData.header,
      employee: {
        ...expenseData.header.employee,
        id: expenseData.header.employee?.id?.toString(),
      },
      anomalyScore: expenseData.header.anomalyScore.toString().slice(0, 5),
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const adaptiveJSON: any = {
    ...updatedExpense,
    auditAttributeOptions,
    expenseAuditData,
    detailsIndex,
    lineLevelBeingAudited,
  };

  if (adaptiveJSON) {
    adaptiveJSON.header.statusImg = mapExpenseStatusIcon(adaptiveJSON?.header?.approvalStatus);

    if (!adaptiveJSON.header.dateSubmitted) {
      adaptiveJSON.header.dateSubmitted = adaptiveJSON.header.dateCreated;
    }

    if (!adaptiveJSON.header.approvalStatus) {
      adaptiveJSON.header.approvalStatus = 'Draft';
    }

    if (adaptiveJSON.approvers && adaptiveJSON.approvers.length > 0) {
      adaptiveJSON.hasInterimApprover = adaptiveJSON.approvers.some(
        (app: any) => app.type && app.type.toLowerCase() == 'interim'
      );
      adaptiveJSON.hasFinalApprover = adaptiveJSON.approvers.some(
        (app: any) => app.type && app.type.toLowerCase() == 'final'
      );

      const currentApproverAlias = adaptiveJSON.header.currentApprover
        ? adaptiveJSON.header.currentApprover.alias
        : null;

      const currentApprover = currentApproverAlias
        ? adaptiveJSON.approvers.filter((a: any) => a.approver.alias == currentApproverAlias)
        : null;
      const currAprSeq = currentApprover && currentApprover.length > 0 ? currentApprover[0].sequenceNumber : null;

      let statusImage: any = '';
      const expenseStatus =
        adaptiveJSON.header.expenseStatus && adaptiveJSON.header.expenseStatus != ''
          ? adaptiveJSON.header.expenseStatus.toLowerCase()
          : 'draft';

      adaptiveJSON.approvers = adaptiveJSON.approvers.map((apr: any) => {
        if (expenseStatus.toLowerCase() == 'draft') {
          statusImage = PendingIcon;
          adaptiveJSON.header.statusImg = DraftIcon;
        } else if (currAprSeq == null) {
          statusImage = expenseStatus == 'rejected' ? RejectedIcon : ApprovedIcon;
        } else if (apr.sequenceNumber < currAprSeq) {
          statusImage = ApprovedIcon;
        } else if (apr.sequenceNumber == currAprSeq) {
          statusImage = InReviewIcon;
        } else if (apr.sequenceNumber > currAprSeq) {
          statusImage = PendingIcon;
        }
        return { ...apr, statusImage };
      });
    }

    if (adaptiveJSON.receipts && adaptiveJSON.receipts.length) {
      adaptiveJSON.receipts = adaptiveJSON.receipts.map((receipt: any) => {
        const { name } = receipt;
        const fileType = name && name.split('.').pop().toLowerCase();
        return {
          ...receipt,
          isPreview: previewFileTypes.includes(fileType),
        };
      });
    }

    if (adaptiveJSON.lines && adaptiveJSON.lines.length > 0) {
      adaptiveJSON.lines = adaptiveJSON.lines.map((line: any, index: number) => {
        const l = { ...line, isExpanded: detailsIndex.includes(index) };
        if (adaptiveJSON.receipts && adaptiveJSON.receipts.length > 0) {
          if (!l.receiptIds || l.receiptIds.length == 0) {
            return { ...l, receipts: [] };
          } else if (l.receiptIds && l.receiptIds.length > 0) {
            const receipts = adaptiveJSON.receipts.filter((r: any) => l.receiptIds.some((a: any) => a == r.id));
            return { ...l, receipts };
          }
        }

        return { ...l };
      });
    }
  }

  const modalDimensions = Styled.getModalDimensions(isModalExpanded, props.windowWidth, props.windowHeight);
  const adaptiveCardPayload = updatePayload([adaptiveCardConfig]);

  return (
    <div>
      <Modal
        titleAriaId={'documentPreviewModal'}
        isOpen={isModalPreviewOpen}
        isBlocking={false}
        dragOptions={{ moveMenuItemText: 'Move', closeMenuItemText: 'Close', menu: ContextualMenu }}
        onDismiss={(): void => {
          dispatch(closeDocumentPreview());
        }}
        styles={{ main: { minWidth: modalDimensions.width, minHeight: modalDimensions.height } }}
      >
        <Stack>
          <Stack.Item align={'end'}>
            <Stack horizontal>
              <IconButton
                iconProps={isModalExpanded ? { iconName: 'BackToWindow' } : { iconName: 'FullScreen' }}
                ariaLabel={isModalExpanded ? 'Restore modal size' : 'Maximize modal'}
                title={isModalExpanded ? 'Resize' : 'Maximize'}
                onClick={(): void => {
                  setIsModalExpanded(!isModalExpanded);
                }}
                styles={{ icon: { fontSize: 18 } }}
              />
              <IconButton
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close preview modal"
                title="Close"
                onClick={(): void => {
                  dispatch(closeDocumentPreview());
                }}
                styles={{ icon: { fontSize: 18 } }}
              />
            </Stack>
          </Stack.Item>
          {isModalPreviewOpen && isLoadingPreview && (
            <Stack.Item verticalFill={true}>
              <Spinner label="Loading preview..." />
            </Stack.Item>
          )}
          {isModalPreviewOpen &&
            !isLoadingPreview &&
            renderFilePreview(true, modalDimensions.width, modalDimensions.height, isModalExpanded)}
        </Stack>
      </Modal>
      <Stack tokens={stackTokens}>
        {shouldDisplayAuditActions && errorMessageToDisplay && (
          <Stack.Item>
            <ErrorView errorMessage={errorMessageToDisplay} />
          </Stack.Item>
        )}
        {expenseSearchErrorMessage && (
          <Stack.Item styles={Styled.loadingSpinnerStyles}>
            <ErrorView errorMessage={expenseSearchErrorMessage}></ErrorView>
          </Stack.Item>
        )}
        {!expenseSearchErrorMessage && (isExpenseSearchInProgress || isLoadingCompleteAuditData) && (
          <Stack.Item styles={Styled.loadingSpinnerStyles}>
            <div>
              <Spinner label="Loading Expense details..." />
            </div>
          </Stack.Item>
        )}
        {!isProcessingAction && <Stack.Item>{detailCardHeaderActionBar()}</Stack.Item>}
        {actionCompleted && !isProcessingAction && !isExpenseSearchInProgress && (
          <Stack.Item styles={Styled.messageBarStyles}>{handleActionResponse()}</Stack.Item>
        )}
        {!isPreviewOpen &&
          !expenseSearchErrorMessage &&
          !isExpenseSearchInProgress &&
          !isLoadingUserImage &&
          adaptiveJSON &&
          !isProcessingAction &&
          (postActionHasError || !actionCompleted) && (
            <Adaptive
              payload={adaptiveCardPayload}
              cardInfo={adaptiveJSON}
              style={Styled.DetailsAdaptiveCard}
              onOpenURLActionExecuted={''}
              onSubmitActionExecuted={handleAdaptiveCardSubmitAction}
            />
          )}

        {isPreviewOpen && isLoadingPreview && (
          <Stack.Item styles={Styled.loadingSpinnerStyles}>
            <div>
              <Spinner label="Loading preview..." />
            </div>
          </Stack.Item>
        )}

        {!isExpenseSearchInProgress && isProcessingAction && !actionCompleted && (
          <Stack.Item styles={Styled.loadingSpinnerStyles}>
            <div>
              <Spinner label="Processing..." />
            </div>
          </Stack.Item>
        )}
        {isPreviewOpen && !isLoadingPreview && <Stack.Item> {renderFilePreview()}</Stack.Item>}
        <Styled.Space></Styled.Space>
      </Stack>
    </div>
  );
}

export { DetailsAdaptive };
