import { SelectionMode, DetailsList, IColumn, DetailsHeader, IDetailsListProps } from '@fluentui/react/lib/DetailsList';
import { UserRoleRecordFieldNames } from './UserRoleTableRecordFieldNames';
import { Reducer } from 'redux';
import {
  ExpenseAuditReducerName,
  expenseAuditReducer,
  ExpenseAuditInitialState,
} from '../../../Shared/Store/ExpenseAudit.reducer';
import { IExpenseAppState, IUserRole, IUserRoleRequest } from '../../../Shared/Store/ExpenseAudit.types';
import { expenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import * as React from 'react';
import { UpdateUserRoleModal } from '../UpdateUserRoleModal';
import { updateUserRole } from '../../../Shared/Store/ExpenseAudit.actions';
import { DialogType, TooltipHost, IconButton, Dialog, DialogFooter, DefaultButton, Text } from '@fluentui/react';
import { TextColors } from '../../../Shared/Styles/SharedColors';

function UserRoleTableColumns(): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ExpenseAuditReducerName, expenseAuditReducer as Reducer, [expenseSagas]);

  const { allUserRoles } = useSelector(
    (state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState
  );

  const [windowWidth, setWindowWidth] = React.useState<number>(0);
  const [sortedItems, setSortedItems] = React.useState<IUserRole[]>(allUserRoles);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedUserRole, setSelectedUserRole] = React.useState(null);
  const [isUpdateUserModalOpen, setUpdateUserModalOpen] = React.useState(false);

  const dialogContentProps: { type: number; title: string; subText?: string } = {
    type: DialogType.normal,
    title: 'Delete user role',
  };

  const createUserRoleRequest = (item: any) => {
    const submitRequest: IUserRoleRequest = {
      userAlias: item.userAlias,
      isAdmin: item.isAdmin,
      auditRole: item.roleName,
    };
    setSelectedUserRole(submitRequest);
    return submitRequest;
  };

  const deleteRow = (item: any) => {
    const submitRequest = createUserRoleRequest(item);
    if (!submitRequest?.auditRole?.userAlias) {
      dialogContentProps.subText = "Are you sure you would like to delete this user's role?";
    }
    setShowDeleteDialog(true);
  };

  const deleteUserRole = (data: any) => {
    dispatch(updateUserRole(selectedUserRole, true));
    setShowDeleteDialog(false);
  };

  const editUserRole = (item: any) => {
    createUserRoleRequest(item);
    setUpdateUserModalOpen(true);
  };

  const allColumns: IColumn[] = [
    {
      key: 'userAlias',
      name: 'User Alias',
      fieldName: UserRoleRecordFieldNames.UserAlias,
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: onSort,
      onRender: (item: any) => {
        return (
          <TooltipHost content={item['userAlias']} aria-label="User Alias">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexWrap: 'nowrap',
                height: '100%',
              }}
            >
              <span style={{ fontSize: '12px', color: TextColors.lightPrimary, textOverflow: 'ellipsis' }}>
                {item['userAlias']}
              </span>
            </div>
          </TooltipHost>
        );
      },
    },
    {
      key: 'roleName',
      name: 'Role',
      fieldName: UserRoleRecordFieldNames.UserRoleName,
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: onSort,
      onRender: (item: any) => {
        return (
          <TooltipHost content={item['roleName']} aria-label="Role Name">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexWrap: 'nowrap',
                height: '100%',
              }}
            >
              <span style={{ fontSize: '12px', color: TextColors.lightPrimary, textOverflow: 'ellipsis' }}>
                {item['roleName']}
              </span>
            </div>
          </TooltipHost>
        );
      },
    },
    {
      key: 'isAdmin',
      name: 'Admin Access',
      fieldName: UserRoleRecordFieldNames.IsAdmin,
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: onSort,
      onRender: (item: any) => {
        return (
          <TooltipHost content={item['isAdmin'] ? 'Yes' : 'No'} aria-label="Is Admin">
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                flexWrap: 'nowrap',
                height: '100%',
              }}
            >
              <span style={{ fontSize: '12px', color: TextColors.lightPrimary, textOverflow: 'ellipsis' }}>
                {item['isAdmin'] ? 'Yes' : 'No'}
              </span>
            </div>
          </TooltipHost>
        );
      },
    },
    {
      key: 'edit',
      name: 'Edit',
      ariaLabel: 'Edit user role',
      fieldName: 'edit',
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: any) => (
        <TooltipHost content="Edit">
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            ariaLabel="Edit user role"
            title="Edit user role"
            onClick={(): void => {
              editUserRole(item);
            }}
            styles={{ icon: { fontSize: 18 } }}
          />
        </TooltipHost>
      ),
    },
    {
      key: 'delete',
      name: 'Delete',
      ariaLabel: 'Delete user role',
      fieldName: 'delete',
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: any) => (
        <TooltipHost content="Delete">
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            ariaLabel="Delete user role"
            title="Delete user role"
            onClick={(): void => {
              deleteRow(item);
            }}
            styles={{ icon: { fontSize: 18 } }}
          />
        </TooltipHost>
      ),
    },
  ];
  const [columns, setColumns] = React.useState<IColumn[]>(allColumns);

  const initialSort = () => {
    const currColumn: IColumn = columns.find((col) => {
      return col.key === 'userAlias';
    });
    const newColumns: IColumn[] = allColumns.slice();
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = false;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(sortedItems, currColumn.fieldName!, currColumn.isSortedDescending);
    setColumns(newColumns);
    setSortedItems(newItems);
  };

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    if (sortedItems) {
      setRoleNames();
      initialSort();
    }

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function onSort(event: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void {
    const newColumns: IColumn[] = allColumns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(sortedItems, currColumn.fieldName!, currColumn.isSortedDescending);
    setColumns(newColumns);
    setSortedItems(newItems);
  }

  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    var key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }

  const onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = (props: any) => {
    return (
      <DetailsHeader
        {...props}
        onRenderColumnHeaderTooltip={(tooltipHostProps: any) => <TooltipHost {...tooltipHostProps} />}
      />
    );
  };

  const setRoleNames = () => {
    sortedItems.forEach((element: any) => {
      element.userAlias = element.userAlias.toLowerCase();
      element.roleName = element.userRole.roleName;
    });
  };

  return (
    <div>
      <DetailsList
        ariaLabel="User roles"
        columns={columns}
        items={sortedItems}
        selectionMode={SelectionMode.none}
        onRenderDetailsHeader={onRenderDetailsHeader as any}
      />
      <Dialog
        hidden={!showDeleteDialog}
        onDismiss={() => setShowDeleteDialog(false)}
        dialogContentProps={dialogContentProps}
      >
        {selectedUserRole?.userAlias && (
          <Text>
            Are you sure you would like to delete the user role for <b>{selectedUserRole.userAlias}</b>?
          </Text>
        )}
        <DialogFooter>
          <DefaultButton
            onClick={() => {
              deleteUserRole(selectedUserRole);
            }}
            text="Delete"
            title="Delete"
            ariaLabel="Delete user role"
            styles={{ root: { backgroundColor: '#0078D4' }, rootHovered: { backgroundColor: '#0078D4' } }}
            primary
          />
          <DefaultButton onClick={() => setShowDeleteDialog(false)} text="Cancel" title="Cancel" ariaLabel="Cancel" />
        </DialogFooter>
      </Dialog>
      <UpdateUserRoleModal
        isOpen={isUpdateUserModalOpen}
        onDismiss={() => setUpdateUserModalOpen(false)}
        userToUpdate={selectedUserRole}
      />
    </div>
  );
}
const connected = withContext(UserRoleTableColumns);
export { connected as UserRoleTableColumns };
