import { Stack } from '@fluentui/react';
import * as React from 'react';
import * as Styled from './DetailsMessageBarsStyling';

interface SuccessViewProps {
  successMessage: string;
}

const SuccessView = ({ successMessage = '' }: SuccessViewProps): JSX.Element => {
  return (
    <Stack verticalAlign="center" horizontalAlign="center">
      <Stack.Item>
        <Styled.SuccessIcon iconName="SkypeCircleCheck" />
      </Stack.Item>
      <Stack.Item>
        <div ref={(input) => input && input.focus()} role="status" tabIndex={0} style={{ outline: 'none' }}>
          <Styled.SuccessMessage>{successMessage}</Styled.SuccessMessage>
        </div>
      </Stack.Item>
    </Stack>
  );
};

export default SuccessView;
