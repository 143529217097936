import { Stack, PersonaSize, Persona } from '@fluentui/react';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { useGraphPhoto } from '@micro-frontend-react/employee-experience/lib/useGraphPhoto';
import * as React from 'react';
import { Reducer } from 'redux';
import { stackStyles, stackTokens } from '../../Shared/Styles/SharedLayout.styled';
import { expenseSagas } from '../../Shared/Store/Expense.sagas';
import { requestMyProfile } from '../../Shared/Store/ExpenseAudit.actions';
import {
  ExpenseAuditReducerName,
  expenseAuditReducer,
  ExpenseAuditInitialState,
} from '../../Shared/Store/ExpenseAudit.reducer';
import { IExpenseAppState } from '../../Shared/Store/ExpenseAudit.types';
import * as HeaderStyled from './SecondaryHeaderStyling';

function SecondaryHeader(): React.ReactElement {
  useDynamicReducer(ExpenseAuditReducerName, expenseAuditReducer as Reducer, [expenseSagas]);

  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

  const { profile, isLoading, hasError } = useSelector(
    (state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState
  );
  const photo = useGraphPhoto();

  React.useEffect(() => {
    dispatch(requestMyProfile());
  }, [dispatch]);

  return (
    <div>
      <HeaderStyled.SecondaryHeaderContainer>
        <Stack horizontal styles={HeaderStyled.SecondaryHeaderStackStyles}>
          <Stack.Item>
            <Stack horizontal styles={stackStyles}>
              <Stack.Item align="center">
                <Stack tokens={stackTokens}>
                  {!isLoading && !hasError && profile && (
                    <>
                      <Persona
                        imageUrl={photo || undefined}
                        size={PersonaSize.size40}
                        text={profile?.data?.displayName}
                      />
                    </>
                  )}
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </HeaderStyled.SecondaryHeaderContainer>
    </div>
  );
}

const connected = withContext(SecondaryHeader);
export { connected as SecondaryHeader };
