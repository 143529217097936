import { Link, Stack } from '@fluentui/react';
import * as HeaderStyled from './HeaderPanels.styled';
import * as SharedStyled from '../../Shared/Styles/SharedLayout.styled';
import { HelpPanelLinks } from './HelpPanelLinks';
import * as React from 'react';

export function HelpPanel(): React.ReactElement {
  return (
    <div>
      <div>
        <SharedStyled.Divider />
        <Stack styles={HeaderStyled.ButtonsStackStyles}>
          {HelpPanelLinks.map((link, index) => (
            <Stack.Item styles={HeaderStyled.LinkStackItemStyles}>
              <Link
                title={link.text}
                ref={(input) => input && index == 0 && input.focus()}
                href={link.link}
                target={link.target ? link.target : null}
              >
                {link.text}
              </Link>
            </Stack.Item>
          ))}
        </Stack>
      </div>
    </div>
  );
}
