export const ApprovedIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOqSURBVHgB1ZrPTttAEMY/G2gjVZWWW2+FJwDurQS33gq39kT6BLSnqgWVIFEKt/TGrfQJyhsQVFXqreEJ4NrbVqJSRELdb9bG5I8T2+vYMT+JsLGdZGZnZmc9YwdjQNWUwhVWOVyEi8fw+N+BklPhRR4ueOwC/3DGcQP30dQ1fYGMOLDECN3BOoci+DJs8NDkax0zOLVVJrUCgeAbHL5G9wxnxcMRFdlJq0gqBdSW2sa4Be9F86+ud/VO0g8kUoCzPoc2vvHqRRSBxMsMVpJYw427QH1Q63SZX4UJLziYk99U79Rq3KVTo07SZTY4G4ccVlA8FU7vi8rTyp/W99bPYRcNVSDw931MGgfPRikRGQPGdFP0+TJxjTX9SR/3Hx5QwASs+Hx+K40tGtNY6g/swSBu4wTlE15QZiXsoycGjN87iI38ieHgEePBYTw0bg8FlNh1+tG4xLyua0l6XS7URg3lF15QeGB2AwZjgWD2z3F3CK3gW+DKcjc5OcQKVRn4Crhmd1kkmvcFn5nlZdPWhA0Onpt/E3AfEX5F7+lQcK5+sjymX/0uMesW7D4Dwhs8WsOGh1h16T5F7TKjhc/CNRYlBhaQP3HCV2EDt90uzTeHbOjY8yOED7L/OmxwseCamwcb5K7Jo2C7epZJcJ7jrxFXxQsPk0Dt8KAcfokHGzxU9UfdI7TaVEdds5mv8AGxt5RDYSmk/xAVqgaWKER4wUW8D0fTjl63jRJt7tsLEF5waPZzyzhIvSyOW3ip9Lmm3GeHov1O1HuVKI+MXXjBEwXAWqU9iZTIRXiBdVaXL1kz40glchNecHDiMh03kJ1IJXIVXujgzAl+SG7kl5EdTb885syIVe2r1klgZZsr3tJ08OaUP7qM7Ch+TxXFUJcXP5HNmDd2+WBSdPxEahRgsUhb78knAXsJ+sAvcN1uJe6SFToI+wehAnfGCryPvpl9YbA2uqWkuFVcLyANzLxceea7D0XVRtdQTlfSdJ2V/oMD/YHWj5auPKn8Ll2N9Bov9b4e6BFENjhYPG1KEXVMuSE74vd7+jDq1NAOjVSAS6GEhzf0+6GdotguJYNaXOkLii/8arrNq6iuTDfJ2qxvWb2bZuPDtgCQFungd9hSOohvs6ZrdG+qGj+xjfwwuYguU0v6gfSPGvjWqFnXcqLxk+hfdumDxkVS7B/2EEWmGOB+Zds28TXMTthC8BusFegmVMZ/3GbBVPt640VmWIeP20hJ/R4a43jc5j+NFXK1wzSLbgAAAABJRU5ErkJggg==';
export const InReviewIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGLSURBVHgBjVPdbcJADLYT57XKCDBBkw3CBG03gAmAtz5AE1oe+oY6QWGC0g3SCRImaEbgpVKlXHB9CYVLgoBPsnT2+fPP2YfQQDIC17qhITMEANyprJghQLrL1Zv/Cpnpj6ayeaJQiBGcASJEt89q1gqQTu13UftwFXjpvRQDfbLKzBMKT5N5W0mrjv5mSovylDxCBx36rvMgZoCZP1exVpPI8VDxgokGWKiDL+eqS+jYYSPr2psXD8mEgnRKH6Wl2H2KrQegpFU6uhKNSF7Fk4xmee3HtJ0xQNFuBPkOJSLDRXBm0DrmDcFVqJNMWPXootnU/ZeanWEm3aU1NkNqycWXafOj30wLFHmgHXQC3uFYJhJJlHoA5FQqwGWzrHLGeRF7c+XLwnThJ19uQj33+q4w46rcxP247vfmzOg5lipcySQ6uvU01TZS1beSBbG9inggawTVsmOjRmnLLsb6ZFV9w1YMPWZewUXwWnx9zTkVGvQGyo8bimNwLFv+A2NqrvdVSCJwtZzz+QPKsLGlaL1lQQAAAABJRU5ErkJggg==';
export const PaidIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAsCAYAAADVX77/AAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFbSURBVHgB7ZpLTsMwEIZ/u12w7BHCDarkAuEG3AA2SbzmAom4QXdRNsBJCAewsmXF4x55MINAqlIkVKmqWs98kq14ks388Vj2eAx+cM6lwzCU9Lg2xqwQKORbO03TY9M0TzxecJfneclGehlRu0DYRNSukySB9/7FZFl2S04/QCDW2itq9gZCoVlfLqlLt40kyGVd1x8IEFrnonEc33/H5Pvazj8K1XnmD99WFsJRASAcFQDCES+AoW3wBMFoCEA44gVYzg10TjYImPmapyEA4agAEI4KAOGoABCOCgDh6FkAZ8Z/+Yt9zzIaAhBOaALsnd47uzXg0PkKDQEIRwWAcFQACKKqqh3bztXYKZXIOOcOnaLnEpm3bYMpiuJ5XickBa4ZtH3fbyAUro1cdF33miQJT7UUcuCwv6dd5ea7UtR738Zx/EnxzyWyEY7P0a7o6a+3NPXvyPmax1+8jmpf5SZiVQAAAABJRU5ErkJggg==';
export const RejectedIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFsSURBVHgBjVRNTsJQEP7mCcadHAF2dic7Ay7KCeQGlhOoJwBOoJ5AOYHeQEyAuLMry84egZ3EhDfOPAl9LT/plzSdfm9+v05LKOAzRO3k19yAOASj7khCSqCYV/Yx+EDq+5P/kLRMX5gBDoExCGZ2uJUgaZsnuUUoh+dgantqmE3l8sGKaN42966D5ELmPDLfuWPiMZjCfIxwKHAr2zAS3M/H8msw4Q4DI48eBVPh5CyX0pjbiohwzj5J1E1a1JcZo6+2cSKpvRa4mytGuCIRj7ELntqH3o7BPhAaWVeZXURFKqXiXC/wo03b6xHW41zna3BcYcY7eQlUxLMZR37bYrsk8xadqkbZlBRTcglZWfOWZcVCRFG1o3w1PCgnZ7WMtB23iZL5xc9cEm4bnYg/x9wDc1o+ltNl1d6p5RI0x1jAbi3PTqhGyyo3XQwKX6NCNSFL8jkjFJFq/06susQSPQwmGKMs9N+g1yGfP3/ImK7tLCs/AAAAAElFTkSuQmCC';
export const PendingIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEXSURBVHgBhVOxkYMwEJQugJQSXAIpkLxLcAX+7+A7sL+D7+BNBe8OcAKklKASSCEA73pkDyNz9iUn6XZPtyuwJoi6rj9FZD9NU2qtTeZ57rHvsC+Lojgtsfa+qKpqE8fxP5ap0cMNw7BFuAfZEyssN+Z9PBoIdwrxjJF/kMvg/D6hsdQIbX/LKknjOJ6iKPpAviATcwgwX0JzwrlgzJHd2ZQZDX5DDHlCV8NC0zSzWRgHfX2Iwc2p8DmMHg71Xdu2h5VaInxHjQniFmkPzHGlzPeXTuH2WZY5yPpWGnfUXCrkhNo1WeSJ/+RWb8/z3CqNHXniR9jxIER415+I3gtzI1ObP3DmdXTEEX+7NKyu/VU0B/npr7oCOzON9rO17RQAAAAASUVORK5CYII=';
export const DraftIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAwCAYAAACFUvPfAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGhSURBVHgB7ZfNbYMwFIAxSuDAJSMwAp2gyQbZoEgIOHaFMkHpDcGh7QbJBF2BDcIITAB9T6pVK6U1Cs+Oo/q7+Ccv4pN59sMsy7LRMYi6rpksxnVukJuUtlgslstgtrhowhYXi8XyjzCtuBRQXJ5kQSad07OEEVOkZwsj0jp/DeI4Dn3ffxzHcY9jaDto3pumecOxcdIgHK3X6w/G2Gbi5xbeyJ1R0hJhTmHMRpwQ7oZh2H19qhZC6IMRKz0l7LrurqqqjseIR/PVV3qOcJIke+Ev/UpjcflxrM0RxhiYe+VjiD3oWumLhc9joK9lI5IJ8xjtG3GpMA60SlMII9qkqYQRLdKUwohyaWphRKm0CmGE6uZCfqz99TCKc1qrMIW0dmGEPKch3U7QhM4vMkuFEdIynqZp5HwL9yqEEepvj4h34F7XqhBGSKVB4p73Qe7I+5TCyMohBFZ3Kww3eZ4/w5UpAllxfpEwQrYR8drved5JErZYGCFLD3j9W0lISyGMkKUH5jOkhziFt4wjpEcbBMGhLMveIYIyp0OQfFEhec4nt7s1BZ7SYyoAAAAASUVORK5CYII=';
export const ProcessingPaymentIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT9SURBVHgB5Zu7cttGFIZ/QFaCTCYzeAMxrlIyXSYTR5xU7qI2lal0SSO5ykxupp041yJ0XkDSE0QqU5mKJ7WpJxDd2ZXWMy7gm+D/LCCSEEESt13evhmJEAUSe/49Z69nHRjGb/k+XmCLl3W42ECImrwNR78OCNHjb8X3e7w+wTm6eBtd1VI9GMSBAfzv/AaN2OK3fz5iaF5CChHimN9zqO6qDiqmMgF0Tb/CDgvbLG30OCIvaWEdx1V5RmkB+oYDu/InbCBCONjHFRyUFWINJfC/93cYq4e8vM4fD7ZwtNANvMaW96mnggfBCQpSyANY6zXW+p4uxHzQoTdsF/GG3B7g/+jfoPJS6x9gfqjRE5veNe9xXm/IJQBd/hbjrw2b7p4dj/4sIYHgv+A464cyC8CubY8P2MX806An+PSEf7PcnEmA2PgmFgUHH3mbXo2ecDTt1qkCLJzxA+pZRJgogI75xXD7cdSntQlju0H/W7/JsfseDKF+VolnU+wQpnDRVHfUQfq/UtD9vIu/sCyco61tSiFVAA5y/oGtYa0d/HjgNsKIANr1Zeq6fDTYoI+0ZwkBtJs4uIVlhbb5u37Cs5Me8Ao3jE1l5wMf7yZ7tb4AuvbDhezv8+FgZ9gLBh7wgjO75a79CxJeMBBgmWP/Mi5DvX+JeA1vNWo/gguz2mYMPKCJVSPUK9V9ATaxaqxxxZq4cd9fw6ohYcDewGXfv4yjvmy8hy0JgQZWldeoiwAbWFUY+iJADUWRbavZU6YMGy6NKDbtDXGbe3Uf8vUAs4LP5sKKlOE2iuCCjWCRHiAyviWXfG3ORAQxXp4dlaFVSIQwCoG8H+peGH+BdRGGjB8qQwsFwiG/AA7q/g/+yOqKNRFSjBf06nWBhZz8AkSFaM5EhEnGF1y6d+M99yKFsSuCAeMlG0UaQYWi2BLBhPHCOZSEQA9lMC2CKeMjHokAj1AWUyKYNV7oujobqwqqFsG88cJDlxOCDqqiKhHsGC+ToRNX/a56hXuCNMqKYMt4GdD9pnrROMDB1H30nF9eTARbxkfP0jvGbvzHIaomrwg2jRfcyOb+FjW3p89gYkPUwb76SW2jAAaTM045i7wqF4OhcIh7MMEYT5iG0cyUoZnjQIB1nf1VfFQ4+YG5RDBsfI+tfz9jpJ8iE3SCwLvmvcMHN2CGTDk7xnOSHNxTv6h+m5ecDZr0AmGKJ1hIyDrlKvj+8BuJJKnYC56zENdhjlRPsJKNFuKm+lUlEqZSk6TYI9yH6eXyod7BkvGn7GavXn77SurNL7HNcHgIk3lCUTjIlBQW8hDP2PB9lvaP1DzB4P9AeZ94TyT3FmapyxIbTBPiq8uuf8HYRMngQdBle+AY7BVsISvYY8c4EzNFKUKHIrxvpZZMELKduatuTrplaq4wRThcSBEi46cOwTNliy+cCBmNFzKfF4hFmPc2QfKN70xz+2FynRiJ24Snko+P+Ts1csZyfc1ZXjvPh4odmvrGr3EEcX9OMkuk1jsc4n4pKzzISalzgzqvWNLrZifEGc0Xl89V68OUOjeoxwofe0ecUj2NG0hbYSGLN3/gGb5Qf5Y7Tlvd0VkJizWdbWrKI8TVZab6Nw1vq7aqZNZq7vB0lHu4WUKMMP4tq9biZUds4DqoGCMCDKM9Y53hcU7vcHU+Uk1npSSFCePzwFKrPd4ru1UneAsd08fn3wAwozZCGR/iPwAAAABJRU5ErkJggg==';
