import { IUserRole, IUserRoleRequest } from './ExpenseAudit.types';
import { IAuditAttributeOptions, IProfile, IAttachment, IUserChangeData } from './ExpenseAudit.types';

export enum ExpenseAuditActionType {
  REQUEST_MY_PROFILE = 'REQUEST_MY_PROFILE',
  RECEIVE_MY_PROFILE = 'RECEIVE_MY_PROFILE',
  FAILED_PROFILE = 'FAILED_PROFILE',
  UPDATE_PANEL_STATE = 'UPDATE_PANEL_STATE',
  SET_DETAILS_DEFAULT_VIEW = 'SET_DETAILS_DEFAULT_VIEW',
  TOGGLE_DETAILS_SCREEN_MAX = 'TOGGLE_DETAILS_SCREEN_MAX',
  TOGGLE_AUDIT_ACTIONS_VIEW = 'TOGGLE_AUDIT_ACTIONS_VIEW',
  FETCH_AUDIT_ATTRIBUTE_OPTIONS = 'FETCH_AUDIT_ATTRIBUTE_OPTIONS',
  SET_AUDIT_ATTRIBUTE_OPTIONS = 'SET_AUDIT_ATTRIBUTE_OPTIONS',
  SUBMIT_AUDIT = 'SUBMIT_AUDIT',
  SUBMIT_LINE_AUDIT = 'SUBMIT_LINE_AUDIT',
  SUBMIT_AUDIT_SUCCESS = 'SUBMIT_AUDIT_SUCCESS',
  SUBMIT_LINE_AUDIT_FAILED = 'SUBMIT_LINE_AUDIT_FAILED',
  SUBMIT_LINE_AUDIT_SUCCESS = 'SUBMIT_LINE_AUDIT_SUCCESS',
  SUBMIT_AUDIT_FAILED = 'SUBMIT_AUDIT_FAILED',
  FETCH_EXPENSE_DATA = 'FETCH_EXPENSE_DATA',
  SET_EXPENSE_DATA = 'SET_EXPENSE_DATA',
  FETCH_COMPLETE_AUDIT_DATA = 'FETCH_COMPLETE_AUDIT_DATA',
  SET_COMPLETE_AUDIT_DATA = 'SET_COMPLETE_AUDIT_DATA',
  REQUEST_DOCUMENT_PREVIEW = 'REQUEST_DOCUMENT_PREVIEW',
  RECEIVE_DOCUMENT_PREVIEW = 'RECEIVE_DOCUMENT_PREVIEW',
  CLEAR_DOCUMENT_PREVIEW = 'CLEAR_DOCUMENT_PREVIEW',
  CLOSE_DOCUMENT_PREVIEW = 'CLOSE_DOCUMENT_PREVIEW',
  REQUEST_DOCUMENT_START = 'REQUEST_DOCUMENT_START',
  REQUEST_DOCUMENT_END = 'REQUEST_DOCUMENT_END',
  REQUEST_DOCUMENT = 'REQUEST_DOCUMENT',
  FAILED_DOCUMENT_PREVIEW = 'FAILED_DOCUMENT_PREVIEW',
  FAILED_DOCUMENT_DOWNLOAD = 'FAILED_DOCUMENT_DOWNLOAD',
  REQUEST_ALL_DOCUMENTS_START = 'REQUEST_ALL_DOCUMENTS_START',
  REQUEST_ALL_DOCUMENTS = 'REQUEST_ALL_DOCUMENTS',
  REQUEST_ALL_DOCUMENTS_END = 'REQUEST_ALL_DOCUMENTS_END',
  FAILED_ALL_DOCUMENT_DOWNLOAD = 'FAILED_ALL_DOCUMENT_DOWNLOAD',
  FETCH_AUDIT_STATUS = 'FETCH_AUDIT_STATUS',
  SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED',
  RECEIVE_USER_IMAGE = 'RECEIVE_USER_IMAGE',
  FAILED_USER_IMAGE = 'FAILED_USER_IMAGE',
  REQUEST_ALL_USER_ROLES = 'REQUEST_ALL_USER_ROLES',
  RECEIVE_ALL_USER_ROLES = 'RECEIVE_ALL_USER_ROLES',
  FAILED_ALL_USER_ROLES = 'FAILED_ALL_USER_ROLES',
  RECEIVE_MY_USER_ROLE = 'RECEIVE_MY_USER_ROLE',
  FAILED__MY_USER_ROLE = 'FAILED__MY_USER_ROLE',
  UPDATE_USER_ROLE = 'UPDATE_USER_ROLE',
  UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS',
  UPDATE_USER_ROLE_FAILED = 'UPDATE_USER_ROLE_FAILED',
  OPEN_AUDIT_SUBMIT_DIALOG = 'OPEN_AUDIT_SUBMIT_DIALOG',
  CHANGE_USER_ROLE_DATA = 'CHANGE_USER_ROLE_DATA',

  // line level audit additions
  UPDATE_IS_LINE_LEVEL_AUDIT = 'UPDATE_IS_LINE_LEVEL_AUDIT',
}

export type ExpenseAuditAction =
  | IRequestProfileAction
  | IReceiveProfileAction
  | IFailedProfileAction
  | IUpdatePanelStateAction
  | IDetailsDefaultViewAction
  | IToggleDetailsScreenMaxAction
  | IToggleAuditActionViewAction
  | IFetchAuditAttributeOptionsAction
  | ISetAuditAttributeOptionsAction
  | ISubmitAuditAction
  | ISubmitLineAuditAction
  | ISubmitAuditActionSuccess
  | ISubmitAuditActionFailed
  | ISubmitLineAuditActionSuccess
  | ISubmitLineAuditActionFailed
  | IFetchExpenseDataAction
  | ISetExpenseDataAction
  | IFetchCompleteAuditDataAction
  | ISetCompleteAuditDataAction
  | IRequestDocumentPreviewAction
  | IReceiveDocumentPreview
  | IClearDocumentPreview
  | ICloseDocumentPreview
  | IRequestDocumentStart
  | IRequestDocumentEnd
  | IRequestDocumentAction
  | IFailedDocumentPreviewAction
  | IFailedDocumentDownloadAction
  | IRequestAllDocumentsStart
  | IRequestAllDocumentsAction
  | IRequestAllDocumentsEnd
  | IFailedAllDocumentDownload
  | ISetAuthorized
  | IReceiveUserImageAction
  | IFailedUserImageAction
  | IRequestAllUserRolesAction
  | IReceiveAllUserRolesAction
  | IFailedAllUserRolesAction
  | IUpdateUserRoleAction
  | IUpdateUserRoleActionSuccess
  | IUpdateUserRoleActionFailed
  | IReceiveMyUserRoleAction
  | IFailedMyUserRoleAction
  | IOpenAuditSubmitDialog
  | ISetUserChangeData

  // line level audit additions
  | IUpdateIsLineLevelAuditAction;

export interface IUpdatePanelStateAction {
  type: ExpenseAuditActionType.UPDATE_PANEL_STATE;
  isOpen: boolean;
}

export interface IToggleDetailsScreenMaxAction {
  type: ExpenseAuditActionType.TOGGLE_DETAILS_SCREEN_MAX;
}

export interface IDetailsDefaultViewAction {
  type: ExpenseAuditActionType.SET_DETAILS_DEFAULT_VIEW;
  defaultView: string;
}

export interface IToggleAuditActionViewAction {
  type: ExpenseAuditActionType.TOGGLE_AUDIT_ACTIONS_VIEW;
  isVisible: boolean;
}

export interface IFetchAuditAttributeOptionsAction {
  type: ExpenseAuditActionType.FETCH_AUDIT_ATTRIBUTE_OPTIONS;
}

export interface ISetAuditAttributeOptionsAction {
  type: ExpenseAuditActionType.SET_AUDIT_ATTRIBUTE_OPTIONS;
  options: IAuditAttributeOptions;
  errorMessage: string | null;
}

export interface IRequestProfileAction {
  type: ExpenseAuditActionType.REQUEST_MY_PROFILE;
}

export interface IReceiveProfileAction {
  type: ExpenseAuditActionType.RECEIVE_MY_PROFILE;
  profile: IProfile;
}

export interface IFailedProfileAction {
  type: ExpenseAuditActionType.FAILED_PROFILE;
  profileErrorMessage: string;
}

export interface ISubmitAuditAction {
  type: ExpenseAuditActionType.SUBMIT_AUDIT;
  auditData: any;
}

export interface ISubmitLineAuditAction {
  type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT;
  auditActionsData: any;
}

export interface ISubmitAuditActionSuccess {
  type: ExpenseAuditActionType.SUBMIT_AUDIT_SUCCESS;
  submitSuccessMessage: string;
}

export interface ISubmitAuditActionFailed {
  type: ExpenseAuditActionType.SUBMIT_AUDIT_FAILED;
  postActionErrorMessage: string;
}

export interface ISubmitLineAuditActionSuccess {
  type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT_SUCCESS;
  submitSuccessMessage: string;
}

export interface ISubmitLineAuditActionFailed {
  type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT_FAILED;
  postActionErrorMessage: string;
}

export interface IFetchExpenseDataAction {
  type: ExpenseAuditActionType.FETCH_EXPENSE_DATA;
  reportId: string;
}

export interface IFetchCompleteAuditDataAction {
  type: ExpenseAuditActionType.FETCH_COMPLETE_AUDIT_DATA;
  reportId: string;
}

export interface ISetExpenseDataAction {
  type: ExpenseAuditActionType.SET_EXPENSE_DATA;
  expenseData: any;
  errorMessage: string | null;
}

export interface ISetCompleteAuditDataAction {
  type: ExpenseAuditActionType.SET_COMPLETE_AUDIT_DATA;
  completeAuditData: any;
  errorMessage: string | null;
}

export interface IClearDocumentPreview {
  type: ExpenseAuditActionType.CLEAR_DOCUMENT_PREVIEW;
}

export interface ICloseDocumentPreview {
  type: ExpenseAuditActionType.CLOSE_DOCUMENT_PREVIEW;
}

export interface IRequestDocumentPreviewAction {
  type: ExpenseAuditActionType.REQUEST_DOCUMENT_PREVIEW;
  attachmentId: string;
  isModal: boolean;
}

export interface IReceiveDocumentPreview {
  type: ExpenseAuditActionType.RECEIVE_DOCUMENT_PREVIEW;
  filePreview: string;
}

export interface IFailedDocumentPreviewAction {
  type: ExpenseAuditActionType.FAILED_DOCUMENT_PREVIEW;
  documentPreviewErrorMessage: string;
}

export interface IRequestDocumentAction {
  type: ExpenseAuditActionType.REQUEST_DOCUMENT;
  actionId: string;
  attachmentName: string;
  attachmentId?: string;
}

export interface IRequestDocumentStart {
  type: ExpenseAuditActionType.REQUEST_DOCUMENT_START;
  docName: string;
}

export interface IRequestDocumentEnd {
  type: ExpenseAuditActionType.REQUEST_DOCUMENT_END;
}

export interface IFailedDocumentDownloadAction {
  type: ExpenseAuditActionType.FAILED_DOCUMENT_DOWNLOAD;
  documentDownloadErrorMessage: string;
}

export interface IRequestAllDocumentsAction {
  type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS;
  actionId: string;
  attachmentArray: IAttachment[];
}

export interface IRequestAllDocumentsStart {
  type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_START;
}

export interface IRequestAllDocumentsEnd {
  type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_END;
}

export interface IFailedAllDocumentDownload {
  type: ExpenseAuditActionType.FAILED_ALL_DOCUMENT_DOWNLOAD;
  documentDownloadErrorMessage: string;
}

export interface ISetAuthorized {
  type: ExpenseAuditActionType.SET_IS_AUTHORIZED;
  isAuthorized: boolean;
}

export interface IReceiveUserImageAction {
  type: ExpenseAuditActionType.RECEIVE_USER_IMAGE;
  userImage: any;
}

export interface IFailedUserImageAction {
  type: ExpenseAuditActionType.FAILED_USER_IMAGE;
  userImageErrorMessage: string;
}

export interface IRequestAllUserRolesAction {
  type: ExpenseAuditActionType.REQUEST_ALL_USER_ROLES;
}

export interface IReceiveAllUserRolesAction {
  type: ExpenseAuditActionType.RECEIVE_ALL_USER_ROLES;
  userRoles: IUserRole[];
}

export interface IFailedAllUserRolesAction {
  type: ExpenseAuditActionType.FAILED_ALL_USER_ROLES;
  userRoleErrorMessage: string;
}

export interface IUpdateUserRoleAction {
  type: ExpenseAuditActionType.UPDATE_USER_ROLE;
  userRole: IUserRoleRequest;
  isDeleteRequest: boolean;
}

export interface IUpdateUserRoleActionSuccess {
  type: ExpenseAuditActionType.UPDATE_USER_ROLE_SUCCESS;
  userRoleSuccessMessage: string;
}

export interface IUpdateUserRoleActionFailed {
  type: ExpenseAuditActionType.UPDATE_USER_ROLE_FAILED;
  userRoleErrorMessage: string;
}

export interface IReceiveMyUserRoleAction {
  type: ExpenseAuditActionType.RECEIVE_MY_USER_ROLE;
  currentUserRole: IUserRole;
}

export interface IFailedMyUserRoleAction {
  type: ExpenseAuditActionType.FAILED__MY_USER_ROLE;
  userRoleErrorMessage: string;
}

export interface IOpenAuditSubmitDialog {
  type: ExpenseAuditActionType.OPEN_AUDIT_SUBMIT_DIALOG;
  open: boolean;
}

export interface ISetUserChangeData {
  type: ExpenseAuditActionType.CHANGE_USER_ROLE_DATA;
  change: IUserChangeData ;
}

// line level audit additions
export interface IUpdateIsLineLevelAuditAction {
  type: ExpenseAuditActionType.UPDATE_IS_LINE_LEVEL_AUDIT;
  isLineLevelAudit: boolean;
}
