import { Depths, FontIcon, NeutralColors, FontSizes, IconButton } from '@fluentui/react';

export const ExpensePageMainContainer = styled.div<{ winHeight: any }>`
  height: ${(props) => props.winHeight - 130}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export const DetailCardContainer = styled.div<{ winHeight: any }>`
  height: ${(props) => props.winHeight - 130}px;
  overflow-y: auto;
  background: #ffffff;
`;

export const Card = styled.div<{ isSelected: boolean; lastFailed: boolean }>`
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: ${Depths.depth4};
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
  transition: box-shadow 0.3s;
  box-shadow: ${(props) => (props.isSelected ? Depths.depth64 : 'none')};
  &:hover {
    box-shadow: ${Depths.depth64};
  }
  max-width: 300px;
  cursor: pointer;
  border-left: ${(props) => (props.lastFailed ? '5px solid rgb(168, 0, 0)' : '0px')};

  @media only screen and (max-width: 1023px) {
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardMetadataContainer = styled.div`
  bottom: 4px;
  position: absolute;
  width: 100%;
`;

export const CardMetadata = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const CardMetadataIcon = styled(FontIcon)`
  margin-right: 8px;
`;

export const CardMetadataText = styled.div`
  color: ${NeutralColors.gray130};
  font-size: ${FontSizes.size12};
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 16px;
`;

export const CardFooterItem = styled.div`
  align-items: center;
  display: flex;
`;

export const CardTag = styled.div`
  align-items: center;
  background: #f2f2f2;
  border-radius: 2px;
  display: flex;
  font-size: ${FontSizes.size12};
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CardActionButton = styled(IconButton)`
  height: 40px;
  width: 40px;
`;
