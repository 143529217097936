import { IStackTokens, IStackStyles, FontIcon, FontSizes, FontWeights, Text } from '@fluentui/react';

export const WarningViewStackTokensLargeGap: IStackTokens = { childrenGap: 6 };

export const OtherViewsStackTokensGap: IStackTokens = { childrenGap: 3 };

export const WarningViewStackStylesBottomBorder: IStackStyles = {
  root: { borderBottom: `1px solid gray` },
};

export const SuccessIcon = styled(FontIcon)`
  margin-top: 20vh;
  margin-bottom: 20px;
  font-size: 58px;
  color: #107c10;
`;

export const SuccessMessage = styled(Text).attrs({
  as: 'p',
})`
  font-size: ${FontSizes.size18};
  font-weight: ${FontWeights.semibold};
`;

export const DetailsMessageBarTitle = styled(Text).attrs({
  as: 'p',
})`
  font-size: ${FontSizes.size16};
  font-weight: ${FontWeights.semibold};
`;

export const UnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;
