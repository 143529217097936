import { IExpenseAuditState } from './ExpenseAudit.types';
import { ExpenseAuditAction, ExpenseAuditActionType } from './ExpenseAudit.action-types';
import { DOCKED_VIEW } from '../SharedConstants';

export const ExpenseAuditReducerName = 'ExpenseAuditReducerName';

export const ExpenseAuditInitialState: IExpenseAuditState = {
  profile: null,
  currentUserRole: null,
  isAuthorized: false,
  isLoading: false,
  isPanelOpen: false,
  detailsDefaultView: DOCKED_VIEW,
  maxDetailsScreen: false,
  isAuditActionsVisible: false,
  isLoadingAuditAttributeOptions: false,
  auditAttributeOptionsLoadError: null,
  isLoadingProfile: false,
  hasError: false,
  profileHasError: false,
  profileErrorMessage: null,
  auditAttributeOptions: {
    auditReason: [],
    auditAction: [],
    auditStatus: [],
    auditFeedback: [],
  },
  isExpenseSearchInProgress: false,
  expenseSearchErrorMessage: null,
  expenseData: {},
  documentPreview: null,
  isPreviewOpen: false,
  isLoadingDownload: false,
  documentDownloadHasError: false,
  documentDownloadErrorMessage: null,
  isDocumentDownloading: false,
  isAllDocumentsDownloading: false,
  isLoadingPreview: false,
  isModalPreviewOpen: false,
  isLoadingCompleteAuditData: false,
  completeAuditData: {},
  isLoadingUserImage: false,
  userImage: null,
  userImageHasError: false,
  userImageErrorMessage: null,
  footerHeight: 64,
  panelWidth: 600,
  postActionHasError: false,
  postActionErrorMessage: null,
  submitSuccessMessage: null,
  allUserRoles: [],
  userRoleHasError: false,
  userRoleErrorMessage: null,
  userRoleSuccessMessage: null,
  isLoadingUserRoles: false,
  isUpdatingUserRole: false,
  doneUpdatingUserRole: false,
  openAuditSubmitDialog: false,
  userChangeData: {
    toOpen: false,
    toDelete: false,
    hasError: false,
  },

  // line level audit fields
  isLineLevelAudit: null,
};

export function expenseAuditReducer(
  prev: IExpenseAuditState = ExpenseAuditInitialState,
  action: ExpenseAuditAction
): IExpenseAuditState {
  switch (action.type) {
    case ExpenseAuditActionType.REQUEST_MY_PROFILE:
      return {
        ...prev,
        isLoading: true,
        hasError: false,
        isProcessingAction: false,
      };
    case ExpenseAuditActionType.RECEIVE_MY_PROFILE:
      return {
        ...prev,
        hasError: false,
        profile: action.profile,
      };
    case ExpenseAuditActionType.FAILED_PROFILE:
      return {
        ...prev,
        isLoadingProfile: false,
        isLoading: false,
        profileHasError: true,
        profileErrorMessage: action.profileErrorMessage,
      };
    case ExpenseAuditActionType.SET_IS_AUTHORIZED:
      return {
        ...prev,
        isLoading: false,
        isAuthorized: action.isAuthorized,
      };
    case ExpenseAuditActionType.UPDATE_PANEL_STATE:
      return {
        ...prev,
        isPanelOpen: action.isOpen,
        maxDetailsScreen: action.isOpen ? prev.maxDetailsScreen : false,
      };
    case ExpenseAuditActionType.SET_DETAILS_DEFAULT_VIEW:
      return {
        ...prev,
        detailsDefaultView: action.defaultView,
      };
    case ExpenseAuditActionType.TOGGLE_DETAILS_SCREEN_MAX:
      return {
        ...prev,
        maxDetailsScreen: !prev.maxDetailsScreen,
      };
    case ExpenseAuditActionType.TOGGLE_AUDIT_ACTIONS_VIEW:
      return {
        ...prev,
        isAuditActionsVisible: action.isVisible,
      };
    case ExpenseAuditActionType.FETCH_AUDIT_ATTRIBUTE_OPTIONS:
      return {
        ...prev,
        isLoadingAuditAttributeOptions: true,
        auditAttributeOptionsLoadError: null,
        isProcessingAction: false,
      };
    case ExpenseAuditActionType.SET_AUDIT_ATTRIBUTE_OPTIONS:
      return {
        ...prev,
        isLoadingAuditAttributeOptions: false,
        auditAttributeOptions: action.options,
        auditAttributeOptionsLoadError: action.errorMessage,
      };
    case ExpenseAuditActionType.FETCH_EXPENSE_DATA:
      return {
        ...prev,
        isExpenseSearchInProgress: true,
        expenseSearchErrorMessage: null,
        isProcessingAction: false,
        submitSuccessMessage: null,
      };
    case ExpenseAuditActionType.SET_EXPENSE_DATA:
      return {
        ...prev,
        isExpenseSearchInProgress: false,
        expenseData: action.expenseData,
        expenseSearchErrorMessage: action.errorMessage ? action.errorMessage : prev.expenseSearchErrorMessage,
      };
    case ExpenseAuditActionType.FETCH_COMPLETE_AUDIT_DATA:
      return {
        ...prev,
        isLoadingCompleteAuditData: true,
      };
    case ExpenseAuditActionType.SET_COMPLETE_AUDIT_DATA:
      return {
        ...prev,
        isLoadingCompleteAuditData: false,
        completeAuditData: action.completeAuditData,
        expenseSearchErrorMessage: action.errorMessage ? action.errorMessage : prev.expenseSearchErrorMessage,
      };
    case ExpenseAuditActionType.REQUEST_DOCUMENT_PREVIEW:
      return {
        ...prev,
        isLoadingPreview: true,
        isPreviewOpen: !action.isModal,
        isModalPreviewOpen: action.isModal,
        documentPreviewHasError: false,
        documentDownloadHasError: false,
      };
    case ExpenseAuditActionType.RECEIVE_DOCUMENT_PREVIEW:
      return {
        ...prev,
        documentPreview: action.filePreview,
        isLoadingPreview: false,
      };
    case ExpenseAuditActionType.CLEAR_DOCUMENT_PREVIEW:
      return {
        ...prev,
        documentPreview: null,
      };

    case ExpenseAuditActionType.CLOSE_DOCUMENT_PREVIEW:
      return {
        ...prev,
        isPreviewOpen: false,
        isModalPreviewOpen: false,
      };
    case ExpenseAuditActionType.RECEIVE_USER_IMAGE:
      return {
        ...prev,
        isLoadingUserImage: false,
        userImageHasError: false,
        userImage: action.userImage,
      };
    case ExpenseAuditActionType.FAILED_USER_IMAGE:
      return {
        ...prev,
        isLoadingUserImage: false,
        userImageHasError: true,
        userImageErrorMessage: action.userImageErrorMessage,
      };
    case ExpenseAuditActionType.FAILED_DOCUMENT_PREVIEW:
      return {
        ...prev,
        isLoadingPreview: false,
        documentPreviewHasError: true,
        documentPreviewErrorMessage: action.documentPreviewErrorMessage,
      };
    case ExpenseAuditActionType.REQUEST_DOCUMENT_START:
      return {
        ...prev,
        isDocumentDownloading: true,
        documentDownloadHasError: false,
        documentDownloadErrorMessage: null,
      };
    case ExpenseAuditActionType.REQUEST_DOCUMENT_END:
      return {
        ...prev,
        isDocumentDownloading: false,
      };
    case ExpenseAuditActionType.REQUEST_DOCUMENT:
      return {
        ...prev,
      };
    case ExpenseAuditActionType.FAILED_DOCUMENT_DOWNLOAD:
      return {
        ...prev,
        isDocumentDownloading: false,
        documentDownloadHasError: true,
        documentDownloadErrorMessage: action.documentDownloadErrorMessage,
      };
    case ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_START:
      return {
        ...prev,
        isAllDocumentsDownloading: true,
        documentDownloadHasError: false,
        documentDownloadErrorMessage: null,
      };
    case ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_END:
      return {
        ...prev,
        isAllDocumentsDownloading: false,
      };
    case ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS:
      return {
        ...prev,
      };
    case ExpenseAuditActionType.FAILED_ALL_DOCUMENT_DOWNLOAD:
      return {
        ...prev,
        isAllDocumentsDownloading: false,
        documentDownloadHasError: true,
        documentDownloadErrorMessage: action.documentDownloadErrorMessage,
      };
    case ExpenseAuditActionType.SUBMIT_AUDIT:
      return {
        ...prev,
        isProcessingAction: true,
        postActionHasError: false,
      };
    case ExpenseAuditActionType.SUBMIT_AUDIT_SUCCESS:
      return {
        ...prev,
        submitSuccessMessage: action.submitSuccessMessage,
        isProcessingAction: false,
      };
    case ExpenseAuditActionType.SUBMIT_AUDIT_FAILED:
      return {
        ...prev,
        isProcessingAction: false,
        postActionHasError: true,
        postActionErrorMessage: action.postActionErrorMessage,
      };
    case ExpenseAuditActionType.SUBMIT_LINE_AUDIT:
      return {
        ...prev,
        isProcessingAction: true,
        postActionHasError: false,
      };
    case ExpenseAuditActionType.SUBMIT_LINE_AUDIT_SUCCESS:
      return {
        ...prev,
        submitSuccessMessage: action.submitSuccessMessage,
        isProcessingAction: false,
      };
    case ExpenseAuditActionType.SUBMIT_LINE_AUDIT_FAILED:
      return {
        ...prev,
        isProcessingAction: false,
        postActionHasError: true,
        postActionErrorMessage: action.postActionErrorMessage,
      };
    case ExpenseAuditActionType.REQUEST_ALL_USER_ROLES:
      return {
        ...prev,
        isLoadingUserRoles: true,
        userRoleErrorMessage: null,
        userRoleHasError: false,
      };
    case ExpenseAuditActionType.RECEIVE_ALL_USER_ROLES:
      return {
        ...prev,
        isLoadingUserRoles: false,
        userRoleErrorMessage: null,
        userRoleHasError: false,
        allUserRoles: action.userRoles,
      };
    case ExpenseAuditActionType.FAILED_ALL_USER_ROLES:
      return {
        ...prev,
        isLoadingUserRoles: false,
        userRoleErrorMessage: action.userRoleErrorMessage,
        userRoleHasError: true,
      };
    case ExpenseAuditActionType.UPDATE_USER_ROLE:
      return {
        ...prev,
        isUpdatingUserRole: true,
        doneUpdatingUserRole: false,
        userRoleSuccessMessage: null,
      };
    case ExpenseAuditActionType.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...prev,
        isUpdatingUserRole: false,
        userRoleErrorMessage: null,
        userRoleHasError: false,
        doneUpdatingUserRole: true,
        userRoleSuccessMessage: action.userRoleSuccessMessage,
      };
    case ExpenseAuditActionType.UPDATE_USER_ROLE_FAILED:
      return {
        ...prev,
        isUpdatingUserRole: false,
        userRoleErrorMessage: action.userRoleErrorMessage,
        userRoleHasError: true,
        userRoleSuccessMessage: null,
      };
    case ExpenseAuditActionType.RECEIVE_MY_USER_ROLE:
      return {
        ...prev,
        isLoadingUserRoles: false,
        userRoleErrorMessage: null,
        userRoleHasError: false,
        currentUserRole: action.currentUserRole,
      };
    case ExpenseAuditActionType.FAILED__MY_USER_ROLE:
      return {
        ...prev,
        isLoadingUserRoles: false,
        userRoleErrorMessage: action.userRoleErrorMessage,
        userRoleHasError: true,
        userRoleSuccessMessage: null,
      };
    case ExpenseAuditActionType.OPEN_AUDIT_SUBMIT_DIALOG:
      return {
        ...prev,
        openAuditSubmitDialog: action.open,
      };
    case ExpenseAuditActionType.CHANGE_USER_ROLE_DATA:
      return {
        ...prev,
        userChangeData: {
          toOpen: action.change.toOpen,
          toDelete: action.change.toDelete,
          hasError: action.change.hasError
        }
      };

    // line level audit additions
    case ExpenseAuditActionType.UPDATE_IS_LINE_LEVEL_AUDIT:
      return {
        ...prev,
        isLineLevelAudit: action.isLineLevelAudit,
      };
    default:
      return prev;
  }
}
