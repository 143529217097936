import {
  IStackTokens,
  IStackStyles,
  mergeStyleSets,
  IDropdownStyles,
  Depths,
  IMessageBarStyles,
  FontSizes,
  FontWeights,
  Text,
} from '@fluentui/react';

export const Container = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 2%;
  margin-right: 5%;
`;

export const InnerPageContainer = styled.div`
  padding-top: 16px;
  margin: 0;
`;

export const Space = styled.div`
  margin-bottom: 24px;
`;

export const PageHeading = styled(Text).attrs({
  as: 'h1',
  variant: 'xLarge',
  block: true,
})`
  margin-bottom: 6px;
`;

export const PageDescription = styled(Text).attrs({
  as: 'p',
  block: true,
})`
  margin-bottom: 24px;
`;

export const SectionTitle = styled(Text).attrs({
  as: 'h2',
  variant: 'large',
  block: true,
})`
  margin-bottom: 24px;
`;

export const NoDataFoundMessage = styled(Text).attrs({
  as: 'h2',
  variant: 'large',
  block: true,
})`
  margin-bottom: 24px;
  text-align: center;
`;

export const ApiErrorMessage = styled(Text).attrs({
  as: 'p',
  variant: 'large',
  block: true,
})`
  margin-bottom: 24px;
  text-align: center;
  color: #ff0000;
`;

export const ApiMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CardGroupLabel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5vw;
`;

export const SummaryCount = styled(Text).attrs({
  as: 'p',
})`
  font-weight: bold;
`;

export const SummaryCountLabel = styled(Text).attrs({
  as: 'p',
})`
  font-weight: regular;
  padding-left: 5px;
`;

export const FailedCount = styled(Text).attrs({
  as: 'p',
})`
  color: rgb(168, 0, 0);
  font-weight: bold;
`;

export const FailedCountLabel = styled(Text).attrs({
  as: 'p',
})`
  color: rgb(168, 0, 0);
  padding-left: 5px;
`;

export const stackTokens: IStackTokens = { childrenGap: 3, padding: '10px' };

export const stackStyles: IStackStyles = {
  root: {
    background: '#e5e5e5',
    position: 'fixed',
    width: '100%',
    paddingRight: '4%',
  },
};

export const messageBarStyles: IStackStyles = {
  root: {
    paddingTop: '20px',
  },
};

export const Styles = mergeStyleSets({
  spacing: {
    paddingLeft: '2%',
  },
  summaryCountSpacing: {
    paddingLeft: '2%',
  },
});

export const iconStyling = {
  color: '#323130',
};

export const largeIconStyling = {
  color: '#323130',
  fontSize: '50px',
};

export const smallDropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
export const panelWidth = 600;

export const tooltipCalloutProps = { gapSpace: 0 };

export const tooltipHostContainer = {
  root: {
    display: 'inline-block',
    float: 'right',
  },
};

export const DetailActionIcon = {
  float: 'right',
  marginRight: '20px',
  cursor: 'pointer',
  fontSize: '12pt',
};

export const docPreviewHeaderBarStyles: IStackStyles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 'calc(100% - 16px)',
    background: '#ffffff',
    top: '0px',
    paddingBottom: '5px',
  },
};

export const StickyDetailsHeder: IStackStyles = {
  root: {
    position: 'absolute',
    width: '100%',
    background: 'rgba(255,255,255,0)',
    height: '35px',
  },
};

export const StickyDetailsHederElement: IStackStyles = {
  root: {
    background: 'rgba(255,255,255,1)',
  },
};

export const DetailsAdaptiveCard = {
  marginTop: '5px',
};
export const AuditActionAdaptiveCard = {
  marginTop: '20px',
  height: '55%',
  display: 'flex',
  padding: '1rem',
};

export const errorClose = {
  color: '#FF0000',
};

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  padding-left: 12px;
  bottom: 0;
  width: 100%;
  background: white;
  overflow: hidden;
  box-shadow: ${Depths.depth64};
  margin: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const SmallSpace = styled.div`
  margin-bottom: 12px;
`;
export const ApiSuccessMessage: IMessageBarStyles = {
  root: {
    paddingLeft: '1.5%',
    paddingTop: '0.25%',
    paddingBottom: '0.25%',
    backgroundColor: '#D0E7F8',
    textAlign: 'center',
  },
};

export const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ViewMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const DetailsFilePreviewStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: '0 10px',
};

export const getModalDimensions = (
  isExpanded: boolean,
  windowWidth: number,
  windowHeight: number
): { width: number; height: number } => {
  if (isExpanded) {
    return {
      width: windowWidth * 0.9,
      height: windowHeight * 0.9,
    };
  } else {
    return {
      width: windowWidth * 0.4,
      height: windowHeight * 0.8,
    };
  }
};

export const loadingSpinnerStyles: IStackStyles = {
  root: {
    paddingTop: '48px',
  },
};

export const ErrorResultIcon = styled.div`
  width: 68px;
  height: 68px;
  background-image: url('/images/error-result.svg');
  background-size: 68px 68px;
  fontsize: 68;
  margin: 0 50px;
`;

export const MessageBarTitle = styled(Text).attrs({
  as: 'p',
})`
  font-size: ${FontSizes.size16};
  font-weight: ${FontWeights.semibold};
`;

export const PDFPreviewContainer = styled.div`
  height: calc(70vh);
`;

export const DocumentPreviewStackTokens: IStackTokens = { childrenGap: 3, padding: '0px 0px 10px 0px' };

export const HeaderActionBarMessageStyle = { root: { marginTop: '5px', marginRight: '5px' } };

export const ErrorText = styled.p`
  color: #d73b02;
`;

export const Divider = styled.div`
  position: relative;
  border-top: 1.5px solid #e8e8e8;
`;
