import { IStackTokens, IStackStyles } from '@fluentui/react';

export const AddNewUserStackTokens: IStackTokens = {
  childrenGap: 20,
  maxWidth: 350,
};

export const AddNewUserStackStyles: IStackStyles = {
  root: {
    marginBottom: '8%',
  },
};

export const ModalButtonGroup: IStackStyles = {
  root: {
    alignItems: 'end',
    display: 'flex',
  },
};

export const ModalButtonGroupStackTokens: IStackTokens = {
  childrenGap: 12,
};
