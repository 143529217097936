import { TooltipHost, IconButton } from '@fluentui/react';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import * as React from 'react';
import * as LayoutStyled from '../../../Shared/Styles/SharedLayout.styled';
import { clearDocumentPreview, closeDocumentPreview } from '../../../Shared/Store/ExpenseAudit.actions';

function BackButton(): React.ReactElement {
  const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  return (
    <TooltipHost
      content="Back"
      calloutProps={LayoutStyled.tooltipCalloutProps}
      styles={LayoutStyled.tooltipHostContainer}
    >
      <IconButton
        iconProps={{ iconName: 'Back' }}
        title="Go Back"
        ariaLabel="Go Back"
        onClick={(): void => {
          dispatch(clearDocumentPreview());
          dispatch(closeDocumentPreview());
        }}
      />
    </TooltipHost>
  );
}

const connected = withContext(BackButton);
export { connected as BackButton };
