import {
  ExpenseAuditActionType,
  IUpdatePanelStateAction,
  IDetailsDefaultViewAction,
  IToggleDetailsScreenMaxAction,
  IToggleAuditActionViewAction,
  IFetchAuditAttributeOptionsAction,
  ISetAuditAttributeOptionsAction,
  IRequestProfileAction,
  IFailedProfileAction,
  IReceiveProfileAction,
  ISubmitAuditAction,
  ISubmitAuditActionSuccess,
  ISubmitAuditActionFailed,
  IFetchExpenseDataAction,
  ISetExpenseDataAction,
  IFetchCompleteAuditDataAction,
  ISetCompleteAuditDataAction,
  IRequestDocumentPreviewAction,
  IReceiveDocumentPreview,
  IClearDocumentPreview,
  ICloseDocumentPreview,
  IRequestDocumentStart,
  IRequestDocumentEnd,
  IRequestDocumentAction,
  IFailedDocumentDownloadAction,
  IFailedDocumentPreviewAction,
  IRequestAllDocumentsAction,
  IRequestAllDocumentsEnd,
  IRequestAllDocumentsStart,
  IFailedAllDocumentDownload,
  IReceiveUserImageAction,
  IFailedUserImageAction,
  IRequestAllUserRolesAction,
  IReceiveAllUserRolesAction,
  IFailedAllUserRolesAction,
  IUpdateUserRoleAction,
  IUpdateUserRoleActionSuccess,
  IUpdateUserRoleActionFailed,
  IReceiveMyUserRoleAction,
  IFailedMyUserRoleAction,
  IOpenAuditSubmitDialog,
  ISetUserChangeData,

  // line level additions
  IUpdateIsLineLevelAuditAction,
  ISubmitLineAuditActionSuccess,
  ISubmitLineAuditActionFailed,
  ISubmitLineAuditAction,
} from './ExpenseAudit.action-types';
import { IUserRole, IUserRoleRequest } from './ExpenseAudit.types';
import { IAuditAttributeOptions, IProfile, IAttachment } from './ExpenseAudit.types';

export function updatePanelState(isOpen: boolean): IUpdatePanelStateAction {
  return {
    type: ExpenseAuditActionType.UPDATE_PANEL_STATE,
    isOpen,
  };
}

export function DetailsDefaultView(defaultView: string): IDetailsDefaultViewAction {
  return {
    type: ExpenseAuditActionType.SET_DETAILS_DEFAULT_VIEW,
    defaultView,
  };
}

export function ToggleDetailsScreenMax(): IToggleDetailsScreenMaxAction {
  return {
    type: ExpenseAuditActionType.TOGGLE_DETAILS_SCREEN_MAX,
  };
}

export function toggleAuditActionView(isVisible: boolean): IToggleAuditActionViewAction {
  return {
    type: ExpenseAuditActionType.TOGGLE_AUDIT_ACTIONS_VIEW,
    isVisible,
  };
}
export function fetchAuditAttributeOptions(): IFetchAuditAttributeOptionsAction {
  return {
    type: ExpenseAuditActionType.FETCH_AUDIT_ATTRIBUTE_OPTIONS,
  };
}
export function setAuditAttributeOptions(
  options: IAuditAttributeOptions,
  errorMessage: string | null = null
): ISetAuditAttributeOptionsAction {
  return {
    type: ExpenseAuditActionType.SET_AUDIT_ATTRIBUTE_OPTIONS,
    options,
    errorMessage,
  };
}
export function requestMyProfile(): IRequestProfileAction {
  return {
    type: ExpenseAuditActionType.REQUEST_MY_PROFILE,
  };
}
export function fetchAuditStatus() {
  return {
    type: ExpenseAuditActionType.FETCH_AUDIT_STATUS,
  };
}

export function failedProfile(profileErrorMessage: string): IFailedProfileAction {
  return {
    type: ExpenseAuditActionType.FAILED_PROFILE,
    profileErrorMessage,
  };
}

export function receiveFriendByEmail(profile: IProfile): IReceiveProfileAction {
  return {
    type: ExpenseAuditActionType.RECEIVE_MY_PROFILE,
    profile,
  };
}

export function setAuthorized(isAuthorized: boolean) {
  return {
    type: ExpenseAuditActionType.SET_IS_AUTHORIZED,
    isAuthorized,
  };
}

export function submitAudit(auditData: {}): ISubmitAuditAction {
  return {
    type: ExpenseAuditActionType.SUBMIT_AUDIT,
    auditData,
  };
}

export function submitAuditSuccess(message: string): ISubmitAuditActionSuccess {
  return {
    type: ExpenseAuditActionType.SUBMIT_AUDIT_SUCCESS,
    submitSuccessMessage: message,
  };
}

export function submitAuditFailed(message: string): ISubmitAuditActionFailed {
  return {
    type: ExpenseAuditActionType.SUBMIT_AUDIT_FAILED,
    postActionErrorMessage: message,
  };
}

export function submitLineAudit(auditActionsData: {}): ISubmitLineAuditAction {
  return {
    type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT,
    auditActionsData,
  };
}

export function submitLineAuditSuccess(message: string): ISubmitLineAuditActionSuccess {
  return {
    type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT_SUCCESS,
    submitSuccessMessage: message,
  };
}

export function submitLineAuditFailed(message: string): ISubmitLineAuditActionFailed {
  return {
    type: ExpenseAuditActionType.SUBMIT_LINE_AUDIT_FAILED,
    postActionErrorMessage: message,
  };
}

export function fetchExpenseData(reportId: string): IFetchExpenseDataAction {
  return {
    type: ExpenseAuditActionType.FETCH_EXPENSE_DATA,
    reportId,
  };
}

export function fetchCompleteAuditData(reportId: string): IFetchCompleteAuditDataAction {
  return {
    type: ExpenseAuditActionType.FETCH_COMPLETE_AUDIT_DATA,
    reportId,
  };
}

export function setExpenseData(expenseData: any, errorMessage: string | null = null): ISetExpenseDataAction {
  return {
    type: ExpenseAuditActionType.SET_EXPENSE_DATA,
    expenseData,
    errorMessage,
  };
}

export function setCompleteAuditData(
  completeAuditData: any,
  errorMessage: string | null = null
): ISetCompleteAuditDataAction {
  return {
    type: ExpenseAuditActionType.SET_COMPLETE_AUDIT_DATA,
    completeAuditData,
    errorMessage,
  };
}

export function requestDocumentPreview(attachmentId: string, isModal: boolean): IRequestDocumentPreviewAction {
  return {
    type: ExpenseAuditActionType.REQUEST_DOCUMENT_PREVIEW,
    attachmentId,
    isModal,
  };
}

export function receiveDocumentPreview(filePreview: string): IReceiveDocumentPreview {
  return {
    type: ExpenseAuditActionType.RECEIVE_DOCUMENT_PREVIEW,
    filePreview,
  };
}

export function clearDocumentPreview(): IClearDocumentPreview {
  return {
    type: ExpenseAuditActionType.CLEAR_DOCUMENT_PREVIEW,
  };
}

export function closeDocumentPreview(): ICloseDocumentPreview {
  return {
    type: ExpenseAuditActionType.CLOSE_DOCUMENT_PREVIEW,
  };
}

export function failedDocumentPreview(documentPreviewErrorMessage: string): IFailedDocumentPreviewAction {
  return {
    type: ExpenseAuditActionType.FAILED_DOCUMENT_PREVIEW,
    documentPreviewErrorMessage,
  };
}

export function requestDocumentStart(docName: string): IRequestDocumentStart {
  return { type: ExpenseAuditActionType.REQUEST_DOCUMENT_START, docName };
}

export function requestDocumentEnd(): IRequestDocumentEnd {
  return { type: ExpenseAuditActionType.REQUEST_DOCUMENT_END };
}

export function requestDocument(
  actionId: string,
  attachmentName: string,
  attachmentId?: string
): IRequestDocumentAction {
  return {
    type: ExpenseAuditActionType.REQUEST_DOCUMENT,
    actionId,
    attachmentName,
    attachmentId,
  };
}

export function failedDocumentDownload(documentDownloadErrorMessage: string): IFailedDocumentDownloadAction {
  return {
    type: ExpenseAuditActionType.FAILED_DOCUMENT_DOWNLOAD,
    documentDownloadErrorMessage,
  };
}

export function requestAllDocumentsStart(): IRequestAllDocumentsStart {
  return { type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_START };
}

export function requestAllDocumentsEnd(): IRequestAllDocumentsEnd {
  return { type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS_END };
}

export function requestAllDocuments(actionId: string, attachmentArray: IAttachment[]): IRequestAllDocumentsAction {
  return {
    type: ExpenseAuditActionType.REQUEST_ALL_DOCUMENTS,
    actionId,
    attachmentArray,
  };
}

export function failedAllDocumentDownload(documentDownloadErrorMessage: string): IFailedAllDocumentDownload {
  return {
    type: ExpenseAuditActionType.FAILED_ALL_DOCUMENT_DOWNLOAD,
    documentDownloadErrorMessage,
  };
}

export function receiveUserImage(userImage: any): IReceiveUserImageAction {
  return {
    type: ExpenseAuditActionType.RECEIVE_USER_IMAGE,
    userImage,
  };
}

export function failedUserImage(userImageErrorMessage: string): IFailedUserImageAction {
  return {
    type: ExpenseAuditActionType.FAILED_USER_IMAGE,
    userImageErrorMessage,
  };
}

export function requestAllUserRoles(): IRequestAllUserRolesAction {
  return {
    type: ExpenseAuditActionType.REQUEST_ALL_USER_ROLES,
  };
}

export function receiveAllUserRoles(userRoles: IUserRole[]): IReceiveAllUserRolesAction {
  return {
    type: ExpenseAuditActionType.RECEIVE_ALL_USER_ROLES,
    userRoles,
  };
}

export function failedAllUserRoles(userRoleErrorMessage: string): IFailedAllUserRolesAction {
  return {
    type: ExpenseAuditActionType.FAILED_ALL_USER_ROLES,
    userRoleErrorMessage,
  };
}

export function updateUserRole(userRole: IUserRoleRequest, isDeleteRequest: boolean): IUpdateUserRoleAction {
  return {
    type: ExpenseAuditActionType.UPDATE_USER_ROLE,
    userRole,
    isDeleteRequest,
  };
}

export function updateUserRoleSuccess(message: string): IUpdateUserRoleActionSuccess {
  return {
    type: ExpenseAuditActionType.UPDATE_USER_ROLE_SUCCESS,
    userRoleSuccessMessage: message,
  };
}

export function failedUpdateUserRole(userRoleErrorMessage: string): IUpdateUserRoleActionFailed {
  return {
    type: ExpenseAuditActionType.UPDATE_USER_ROLE_FAILED,
    userRoleErrorMessage,
  };
}

export function receiveMyUserRole(userRole: IUserRole): IReceiveMyUserRoleAction {
  return {
    type: ExpenseAuditActionType.RECEIVE_MY_USER_ROLE,
    currentUserRole: userRole,
  };
}

export function failedMyUserRole(userRoleErrorMessage: string): IFailedMyUserRoleAction {
  return {
    type: ExpenseAuditActionType.FAILED__MY_USER_ROLE,
    userRoleErrorMessage,
  };
}

export function setOpenAuditSubmitDialog(open: boolean): IOpenAuditSubmitDialog {
  return {
    type: ExpenseAuditActionType.OPEN_AUDIT_SUBMIT_DIALOG,
    open,
  };
}
export function setUserChangeData(toOpen: boolean, toDelete: boolean, hasError: boolean): ISetUserChangeData {
  return {
    type: ExpenseAuditActionType.CHANGE_USER_ROLE_DATA,
    change: {
      toOpen,
      toDelete,
      hasError,
    }
  };
}
// line level audit additions
export function setIsLineLevelAudit(isLineLevelAudit: boolean): IUpdateIsLineLevelAuditAction {
  return {
    type: ExpenseAuditActionType.UPDATE_IS_LINE_LEVEL_AUDIT,
    isLineLevelAudit,
  };
}
