import * as React from 'react';
import { Adaptive } from '../../Helpers/AdaptiveCardHelpers/Adaptive';
import adaptiveCardConfig from './ExpenseAuditSearchFormAdaptiveCardConfig';
import { clearDocumentPreview, closeDocumentPreview } from '../../Shared/Store/ExpenseAudit.actions';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

const SearchFormContainer = styled.div`
  background: #fff;
  padding: 0.5rem;
  margin: 1rem;
  max-width: 20rem;
`;

const adaptiveCardConfigPayload = [adaptiveCardConfig];

function ExpenseAuditSearchFormAdaptive(props: any): React.ReactElement {
  const [updateCount, setUpdateCount] = React.useState(1);
  const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const onSubmit = (id: string, data: object) => {
    if (id === 'ActionClear') {
      setUpdateCount((updateCount) => updateCount + 1);
      dispatch(setExpenseMessage(null, null));
    } else {
      dispatch(closeDocumentPreview());
      dispatch(clearDocumentPreview());
      props.onSearch(data.searchTerm);
    }
  };
  return (
    <SearchFormContainer>
      <Adaptive payload={adaptiveCardConfigPayload} onSubmitActionExecuted={onSubmit} />
    </SearchFormContainer>
  );
}

export default ExpenseAuditSearchFormAdaptive;
