import * as React from 'react';
import * as Styled from './DetailsMessageBarsStyling';
import * as sanitizeHtml from 'sanitize-html';
import { Stack, MessageBar, MessageBarType, Link } from '@fluentui/react';

interface ErrorViewProps {
  errorMessage?: string;
  errorMessages?: string[];
  failureType?: string;
  customTitle?: string;
  linkHref?: string;
  linkText?: string;
  dismissHandler?: any;
}

const ErrorView = ({
  errorMessage = '',
  failureType = '',
  customTitle = '',
  linkHref = null,
  linkText = null,
  dismissHandler = null,
  errorMessages = null,
}: ErrorViewProps): JSX.Element => {
  const [errorViewRef, setErrorViewRef] = React.useState(null);

  React.useEffect(() => {
    if (errorViewRef) {
      errorViewRef.focus();
    }
  }, [errorViewRef]);

  const cleanErrorMessage = errorMessage
    ? sanitizeHtml(errorMessage, {
        allowedTags: ['a'],
        allowedAttributes: {
          a: ['href', 'target'],
        },
      })
    : '';
  const messageElements = errorMessages?.map((item: string, index: number) => (
    <Stack.Item styles={errorMessages.length > 1 && Styled.WarningViewStackStylesBottomBorder} key={index}>
      <li>{item}</li>
    </Stack.Item>
  ));
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      aria-label={'Error message'}
      onDismiss={dismissHandler}
      dismissButtonAriaLabel={'Dismiss error message'}
    >
      <Stack tokens={Styled.OtherViewsStackTokensGap}>
        {(failureType || customTitle) && (
          <Stack.Item>
            <Styled.DetailsMessageBarTitle>
              {failureType ? `${failureType} failed.` : customTitle}
            </Styled.DetailsMessageBarTitle>
          </Stack.Item>
        )}
        {cleanErrorMessage && (
          <Stack.Item>
            <div dangerouslySetInnerHTML={{ __html: cleanErrorMessage }} />
          </Stack.Item>
        )}
        {errorMessages && (
          <Stack.Item>
            <div
              ref={(input) => {
                setErrorViewRef(input);
              }}
              role="status"
              aria-label={failureType ? `${failureType} failed.` : customTitle}
              title={failureType ? `${failureType} failed.` : customTitle}
              tabIndex={0}
              style={{ outline: 'none' }}
            >
              <Styled.UnorderedList>
                <Stack>{messageElements}</Stack>
              </Styled.UnorderedList>
            </div>
          </Stack.Item>
        )}
        <Stack.Item>{linkHref && <Link href={linkHref}>{linkText}</Link>}</Stack.Item>
      </Stack>
    </MessageBar>
  );
};

export default ErrorView;
