import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminPage } from './Pages/AdminView/AdminPage';
import { DashboardPage } from './Pages/Dashboard/DashboardPage';

export function Routes(): React.ReactElement {
  return (
    <Switch>
      <Route path="/" exact={true} component={DashboardPage} />
      <Route path="/AdminView" component={AdminPage} exact={true} />
    </Switch>
  );
}
