import * as React from 'react';
import { Reducer } from 'redux';
import * as SharedStyled from '../../Shared/Styles/SharedLayout.styled';
import * as Styled from './AdminPageStyling';
import { UpdateUserRoleModal } from './UpdateUserRoleModal';
import { UserRoleTableColumns } from './UserRoleTable/UserRoleTableColumns';
import { requestAllUserRoles, setUserChangeData } from '../../Shared/Store/ExpenseAudit.actions';
import {
  ExpenseAuditReducerName,
  expenseAuditReducer,
  ExpenseAuditInitialState,
} from '../../Shared/Store/ExpenseAudit.reducer';
import { IExpenseAppState } from '../../Shared/Store/ExpenseAudit.types';
import { expenseSagas } from '../../Shared/Store/Expense.sagas';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Dialog, DialogType, DialogFooter, IDialogContentProps, PrimaryButton, Stack, DefaultButton, Spinner, ScrollablePane, Text, Link } from '@fluentui/react';

export function AdminPage(): React.ReactElement {
  const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  useDynamicReducer(ExpenseAuditReducerName, expenseAuditReducer as Reducer, [expenseSagas]);
  const { allUserRoles, isLoadingUserRoles, userRoleErrorMessage, userRoleHasError, currentUserRole, userChangeData } = useSelector(
    (state: IExpenseAppState) => state.dynamic?.[ExpenseAuditReducerName] || ExpenseAuditInitialState
  );

  const initialDialogProps: IDialogContentProps = {
    type: DialogType.normal,
    title: '',
    subText: ''
  };

  const [dialogContentProps, setDialogContentProps] = React.useState<IDialogContentProps>(initialDialogProps);

  const NOT_AUTHORIZED_TEXT =
    'You are not authorized to see this page. If you should have administrator access, please request access ';
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  React.useEffect(() => {
    dispatch(requestAllUserRoles());
  }, []);

  React.useEffect(() => {
    if (userChangeData.toOpen) {
      let title = '';
      let subText = '';
      // When user deletes a user
      if (userChangeData.toDelete) {
        title = userChangeData.hasError ? 'Error deleting user role' : 'User role deleted';
        subText = userChangeData.hasError ? 'An error occurred while deleting user role' : 'User role successfully deleted';
      } else {
        // When adding new user
        title = userChangeData.hasError ? 'Error adding or updating user role' : 'User role added or updated successfully';
        subText = userChangeData.hasError ? 'An error occurred while adding or updating a user role' : 'User role has been added or updated successfully';
      }
      setDialogContentProps({
        type: DialogType.normal,
        title,
        subText
      })
    }
  }, [userChangeData]);

  const launchModal = () => {
    setModalOpen(true);
  };

  const closeModal = (): void => {
    setModalOpen(false);
  };

  return (
    <div>
      <Dialog
          hidden={!userChangeData.toOpen}
          dialogContentProps={dialogContentProps}
              >
        <DialogFooter>
          <PrimaryButton onClick={() => dispatch(setUserChangeData(false, false, false))} text="Done" />
        </DialogFooter>
      </Dialog>
      <SharedStyled.InnerPageContainer>
        <Styled.SummaryContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
          <Styled.AdminViewTitle>Audit User Roles</Styled.AdminViewTitle>
          {currentUserRole && currentUserRole.isAdmin && (
            <div>
              <Stack
                horizontal
                horizontalAlign="space-between"
                wrap
                styles={{ root: { paddingBottom: '1.5%', width: '100%' } }}
              >
                <Stack horizontal tokens={Styled.AdminNavStackTokens} styles={{ root: { width: '100%' } }}>
                  <Stack.Item align="end" styles={{ root: { marginLeft: 'auto !important', color: 'red' } }}>
                    <DefaultButton
                      text="Refresh List"
                      title="Refresh List"
                      onClick={() => dispatch(requestAllUserRoles())}
                      ariaLabel="Refresh List"
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <PrimaryButton
                      text="Add New User"
                      title="Add New User"
                      onClick={launchModal}
                      ariaLabel="Add new user"
                    />
                    <UpdateUserRoleModal isOpen={isModalOpen} onDismiss={closeModal} />
                  </Stack.Item>
                </Stack>
              </Stack>
              {isLoadingUserRoles && (
                <Styled.SpinnerContainer>
                  <Spinner label="Loading user roles..." />
                </Styled.SpinnerContainer>
              )}
              {/* {!isLoadingUserRoles && !userRoleHasError && allUserRoles && allUserRoles.length === 0 && (
                                <EmptyResults message="No Historical Records Found" />
                            )} */}
              {!isLoadingUserRoles &&
                !userRoleHasError &&
                !userRoleErrorMessage &&
                allUserRoles &&
                allUserRoles.length !== 0 && (
                  <Stack grow>
                    <Styled.AdminTableContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
                      <Stack
                        style={{
                          height: dimensions.width < 1024 ? '100%' : `${dimensions.height - 250}px`,
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            height: dimensions.width < 1024 ? '90%' : `${dimensions.height - 350}px`,
                            overflowY: 'hidden',
                            marginBottom: '1%',
                          }}
                        >
                          <ScrollablePane>
                            <UserRoleTableColumns />
                          </ScrollablePane>
                        </div>
                        {/* <Stack horizontal horizontalAlign="center">
                                                <Pagination {...paginationProps} />
                                            </Stack> */}
                      </Stack>
                    </Styled.AdminTableContainer>
                  </Stack>
                )}
              {userRoleHasError && userRoleErrorMessage && (
                <Stack.Item>
                  <Text> {userRoleErrorMessage} </Text>
                </Stack.Item>
              )}
            </div>
          )}
          {currentUserRole && !currentUserRole.isAdmin && (
            <div>
              <Text>{NOT_AUTHORIZED_TEXT}</Text>
              <Link
                href="https://forms.office.com/pages/responsepage.aspx?id=v4j5cvGGr0GRqy180BHbR6fdjfTc4B1HsfvxBL3hELNUOFZITVlMR0ZQNDE1U0hDS003ODFHOFZEQy4u"
                aria-label="Click here to request admin access"
                target="_blank"
                style={{ color: '#003C94' }}
              >
                here.
              </Link>
            </div>
          )}
        </Styled.SummaryContainer>
      </SharedStyled.InnerPageContainer>
    </div>
  );
}
