import * as React from 'react';
import { ICoherenceHeaderProps } from '@coherence-design-system/controls/lib/header';
import { HelpPanel } from './Components/Header/HelpPanel';

export function useHeaderConfig(): ICoherenceHeaderProps {
  return {
    headerLabel: __APP_NAME__,
    appNameSettings: {
      label: __APP_NAME__,
    },
    farRightSettings: {
      helpSettings: {
        panelSettings: {
          body: <HelpPanel />,
        },
      },
      feedbackSettings: {
        panelSettings: {
          ocvButtonIsFocused: false,
          onClick: () => {
            return true;
          },
          launchOptions: {
            categories: {
              show: true,
              customCategories: ['Dashboard', 'Feed', 'Catalog', 'Vision', 'Hearing', 'Mobility', 'Cognitive'],
            },
          },
        },
      },
    },
  };
}
