import { IStackTokens } from '@fluentui/react';

export const SummaryContainer = styled.div<any>`
  padding: 1% 2% 0 2%;
  height: ${(props: any) => props.windowHeight - 150}px;

  @media only screen and (max-device-width: 480px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 320px) {
    height: ${(props: any) => props.windowHeight - 50}px;
  }
`;

export const AdminTableContainer: any = styled.div`
  height: ${(props: any) => props.windowHeight - 250}px;

  @media only screen and (max-device-width: 480px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 320px) {
    height: ${(props: any) => props.windowHeight - 50}px;
  }
`;

export const AdminViewTitle = styled.h1`
  padding-bottom: 12px;
`;

export const AdminNavStackTokens: IStackTokens = {
  childrenGap: 15,
};

export const SpinnerContainer = styled.div`
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
