import { Stack } from '@fluentui/react';
import * as React from 'react';
import { ExpenseAuditPage } from '../ExpenseAudit/ExpenseAuditPage';

export function DashboardPage(): React.ReactElement {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div>
      <Stack>
        <Stack.Item>
          <ExpenseAuditPage windowHeight={dimensions.height} windowWidth={dimensions.width} />
        </Stack.Item>
      </Stack>
    </div>
  );
}
