export default {
    "type": "AdaptiveCard",
    "version": "1.5",
    "fallbackText": "This device does not support yet, please upgrade your outlook version",
    "body": [
        {
            "type": "Input.Text",
            "id": "searchTerm",
            "label": "Search Expense Report",
            "isRequired": true,
            "errorMessage": "Please enter Expense Report"
        },
        {
            "type": "ColumnSet",
            "columns": [
                {
                    "type": "Column",
                    "items": [
                        {
                            "type": "ActionSet",
                            "actions": [
                                {
                                    "type": "Action.Submit",
                                    "title": "Search",
                                    "style": "positive",
                                    "id": "ActionSubmit"
                                }
                            ]
                        }
                    ],
                },
                {
                    "type": "Column",
                    "items": [
                        {
                            "type": "ActionSet",
                            "actions": [
                                {
                                    "type": "Action.Submit",
                                    "title": "Clear",
                                    "style": "destructive",
                                    "associatedInputs": "none",
                                    "id": "ActionClear"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    "additionalProperties": {
        "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
        "correlationId": null,
        "constrainWidth": true,
        "hideOriginalBody": true,
        "padding": "None"
    },
    "internalID": {},
    "originator": "b3bb9c26-77fc-4694-a14d-e5b9c2fffc73",
    "correlationId": null,
    "constrainWidth": true,
    "hideOriginalBody": true,
    "padding": "None",
    "height": "stretch"
};